import { Settings } from "./static/settings";
import { Util } from "./static/util";

export class Pattern {
  grid: number[][];
  sizeX: number;
  sizeY: number;
  dirtyPositionsGrid: boolean[][];
  id: number;
  dx: number;
  dy: number;
  brush: boolean; // Used to check if outlines should be made
  eraser: boolean; // Necessary, or else -1 colors are ignored
  constructor(grid: number[][], sizeX?: number, sizeY?: number) {
    this.grid = grid;
    this.sizeX = sizeX ?? grid[0].length;
    this.sizeY = sizeY ?? grid.length;
    this.dirtyPositionsGrid = [];
    this.setDirty();
    this.id = -1;

    this.dx = -1;
    this.dy = -1;
    this.updateDxDy();
    this.brush = false;
    this.eraser = false;
  }

  updateDxDy() {
    // const minGridSizeX = 52;
    // const minGridSizeY = 12;

    this.dx = 0; //Math.max(Math.ceil((minGridSizeX - this.sizeX) / 2), 0)
    this.dy = 0; //Math.max(Math.ceil((minGridSizeY - this.sizeY) / 2), 0)
  }

  setDirty() {
    function make2DArray(x: number, y: number, fillWith: any) {
      return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
    }
    this.dirtyPositionsGrid = make2DArray(
      this.grid[0].length,
      this.grid.length,
      true
    );
  }

  anchor() {
    return [
      Math.floor((this.grid[0].length - 1) / 2),
      Math.floor((this.grid.length - 1) / 2),
    ];
  }
  length() {
    return [this.grid[0].length, this.grid.length];
  }

  isSweaterPart() {
    return false;
  }

  fillGrid() {
    const emptyGrid = Util.emptyPattern().grid;
    const newGrid: number[][] = [];
    for (let y = 0; y < emptyGrid.length; y++) {
      newGrid.push([]);
      for (let x = 0; x < emptyGrid[0].length; x++) {
        if (x >= this.sizeX || y >= this.sizeY) {
          newGrid[y].push(emptyGrid[y][x]);
        } else {
          newGrid[y].push(this.grid[y][x]);
        }
      }
    }
    this.grid = newGrid;
    this.setDirty();
  }

  updateGrid(x: number, y: number, newValue: number) {
    if (this.grid[y][x] !== newValue) {
      this.grid[y][x] = newValue;
      this.dirtyPositionsGrid[y][x] = true;
    }
  }

  copyGrid() {
    const shallowGrid: number[][] = [];
    for (let innerGrid of this.grid) {
      shallowGrid.push([...innerGrid]);
    }
    return shallowGrid;
  }

  copyGridBySize() {
    const shallowGrid: number[][] = [];
    for (let y = 0; y < this.sizeY; y++) {
      shallowGrid.push([] as number[]);
      for (let x = 0; x < this.sizeX; x++) {
        const innerGrid = this.grid[y][x];
        shallowGrid[y].push(innerGrid);
      }
    }
    return shallowGrid;
  }

  // Instead of sizeY argument; could update this.sizeY immediately instead of with useState.
  calculateEditPatternHeight(sizeY: number | undefined) {
    return (
      Math.round(
        Settings.defaultPatternHeightVH *
          Math.max(1, (sizeY ?? this.sizeY) / 12)
      ) + 0.1
    );
  }

  isMask(x: number, y: number) {
    const drawX = x - this.dx >= 0 && x - this.dx < this.sizeX;
    const drawY = y - this.dy >= 0 && y - this.dy < this.sizeY;
    return drawX && drawY;
  }

  // Unused, but could be useful if people lose track of their colors/mouse
  // and therefore needs outlines as indicators
  getOutlines(x: number, y: number) {
    if (x < 0) {
      return [false, false, true, false];
    }
    if (x > this.sizeX) {
      return [false, true, false, false];
    }
    if (y < 0) {
      return [true, false, false, false];
    }
    if (y > this.sizeY) {
      return [false, false, false, true];
    }
    const up = (this.grid[y][x] === -1) !== (this.grid[y - 1][x] === -1);
    const right = (this.grid[y][x] === -1) !== (this.grid[y][x - 1] === -1);
    const down = (this.grid[y][x] === -1) !== (this.grid[y + 1][x] === -1);
    const left = (this.grid[y][x] === -1) !== (this.grid[y][x + 1] === -1);
    return [up, right, down, left];
  }
}
