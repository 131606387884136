import { Transition } from "@headlessui/react";
import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
  InfoCircledIcon,
} from "@radix-ui/react-icons";
import cn from "classnames";
import React, { useMemo } from "react";

type AlertSeverity = "error" | "warning" | "info" | "success";

export type AlertValues = {
  severity?: AlertSeverity;
  message?: string;
};

type Props = {
  severity?: AlertSeverity;
  className?: string;
  children?: React.ReactNode;
};

const Alert: React.FC<React.PropsWithChildren<Props>> = ({
  severity = "info",
  className,
  children,
}) => {
  //EDIT severity !== "success" && logEvent("User alerted", { severity });

  const icon = useMemo(() => {
    switch (severity) {
      case "info":
        return <InfoCircledIcon />;
      case "success":
        return <CheckCircledIcon />;
      case "warning":
        return <ExclamationTriangleIcon />;
      case "error":
        return <ExclamationTriangleIcon />;
    }
  }, [severity]);

  return (
    <Transition
      show
      appear
      as="div"
      enter="ease-out duration-200"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      className={cn(
        "flex w-full items-center gap-x-2 rounded p-2",
        {
          "bg-darkest-brown/10": severity === "info",
          "bg-positive/10 text-positive": severity === "success",
          "bg-contrast/10 text-contrast": severity === "warning",
          "bg-negative/10 text-negative": severity === "error",
        },
        className
      )}
    >
      {icon}
      <div
        className={cn("text-par-s-reg w-full", {
          "text-positive": severity === "success",
          "text-contrast": severity === "warning",
          "text-negative": severity === "error",
        })}
      >
        {children}
      </div>
    </Transition>
  );
};

export default Alert;
