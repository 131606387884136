import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

// Define a type for the slice state
interface KeyState {
  key: string
}

// Define the initial state using that type
const initialState: KeyState = {
  key: "",
}

export const keySlice = createSlice({
  name: 'key',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setKey: (state, action: PayloadAction<string>) => {
      state.key = action.payload
    },
  },
})

export const { setKey } = keySlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectKey = (state: RootState) => state.key.key

export default keySlice.reducer