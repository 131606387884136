import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import React from "react";
import { KnitabilityProblem } from "../sweaterdesigner/enums";

// Define a type for the slice state
interface knitabilityState {
  isKnitable: KnitabilityProblem;
  warningOverlay: boolean;
}

// Define the initial state using that type
const initialState: knitabilityState = {
  isKnitable: KnitabilityProblem.None,
  warningOverlay: false,
};

export const knitabilitySlice = createSlice({
  name: "knitability",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setKnitability: (state, action: PayloadAction<KnitabilityProblem>) => {
      state.isKnitable = action.payload;
    },
    setWarningOverlay: (state, action: PayloadAction<boolean>) => {
      state.warningOverlay = action.payload;
    },
  },
});

export const { setKnitability, setWarningOverlay } = knitabilitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectknitability = (state: RootState) => state.knitability;

export default knitabilitySlice.reducer;
