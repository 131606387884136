import React, { useEffect, useState } from "react";
import "./SweaterDesigner.css";

import KnittingPreview from "./knittingpreview/KnittingPreview";
import KnittingEditor from "./knittingeditor/KnittingEditor";
import { SweaterPart } from "./SweaterPart";
import { Pattern } from "./Pattern";
import mixpanel from "mixpanel-browser";
import Modal from "./login/Modal";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Header from "./header/Header";
import StartMenu from "./StartMenu";

function SweaterDesigner() {
  const [selectedSweaterPart, setSelectedSweaterPart] = useState<
    SweaterPart | undefined
  >(undefined);
  const [selectedEditPattern, setSelectedEditPattern] = useState<
    Pattern | undefined
  >(undefined);

  useEffect(() => {
    mixpanel.track("SweaterDesignerLoaded", {});
  });

  const [sweaterHasLoaded, setSweaterHasLoaded] = useState<Boolean>(false);
  const [saving, setSaving] = useState<Boolean>(false);
  const [startMenuOpen, setStartMenuOpen] = useState<Boolean>(true);
  return (
    <>
      <MobileView>
        <div className="mobileOnly">
          This site is not made for mobile. Please use a computer.
        </div>
      </MobileView>
      <StartMenu
        startMenuOpen={startMenuOpen}
        setStartMenuOpen={setStartMenuOpen}
      />
      <div
        style={{
          display: "flex",
          height: "5vh",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <Header saving={saving} />
      </div>
      <div
        style={{
          display: "flex",
          backgroundColor: "#f9f5f2",
          paddingBottom: "0vh",
          position: "relative",
          height: "95vh", // Would really like a flex: 1 solution, but I couldnt find one
        }}
      >
        <div
          style={{
            width: "50%",
          }}
        >
          {!selectedSweaterPart && (
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "50%",
                display: "flex",
                backgroundColor: "black",
                opacity: 0.6,
                borderRadius: "3px",
                zIndex: 1,
              }}
            >
              <div
                style={{
                  margin: "auto",
                  fontSize: "28px",
                  color: "white",
                  fontWeight: 100,
                }}
              >
                Click on any part of the sweater to start
              </div>
            </div>
          )}
          <KnittingEditor
            selectedSweaterPart={selectedSweaterPart}
            selectedEditPattern={selectedEditPattern}
            setSelectedEditPattern={setSelectedEditPattern}
            setSelectedSweaterPart={setSelectedSweaterPart}
            setSweaterHasLoaded={setSweaterHasLoaded}
            startMenuOpen={startMenuOpen}
          />
        </div>
        <div
          style={{
            width: "50%",
          }}
        >
          {!sweaterHasLoaded && (
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "50%",
                display: "flex",
                zIndex: 1,
              }}
            >
              <div
                style={{
                  margin: "auto",
                  fontSize: "30px",
                  color: "black",
                  fontWeight: 100,
                }}
              >
                Loading 3D model...
              </div>
            </div>
          )}
          <KnittingPreview saving={saving} setSaving={setSaving} />
        </div>
      </div>
    </>
  );
}

export default SweaterDesigner;
