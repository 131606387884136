import Button from "../../components/ui/Button/Button";
import FacebookButton from "../../components/ui/Button/FacebookButton";
import Input from "../../components/ui/Input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import mixpanel from "mixpanel-browser";
import React, { useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { LoginUserCredentials, LoginUserSchema } from "../../types/auth";

type Props = {
  onSubmit: (loginCredentials: LoginUserCredentials) => void;
  loading?: boolean;
  defaultValues?: Omit<Partial<LoginUserCredentials>, "password">;
  className?: string;
};

type LoginFieldErrors = {
  email?: FieldError | undefined;
  password?: FieldError | undefined;
};

const LoginForm: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  loading,
  defaultValues,
  className,
}) => {
  const { handleSubmit, control } = useForm<LoginUserCredentials>({
    defaultValues,
    resolver: yupResolver(LoginUserSchema),
  });

  const [errors, setErrors] = useState<LoginFieldErrors>();

  const handleValid = (credentials: LoginUserCredentials) =>
    onSubmit(credentials);

  const handleInvalid = (fieldErrors: LoginFieldErrors) =>
    setErrors(fieldErrors);

  return (
    <div className={cn("flex flex-col gap-y-10", className)}>
      <p className="text-par-b-reg text-center">
        Logg inn med din Woolit-konto.
      </p>
      <form
        onSubmit={handleSubmit(handleValid, handleInvalid)}
        className="flex flex-col items-center gap-y-3"
      >
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange, name } }) => (
            <Input
              className="w-full"
              value={value}
              onChange={onChange}
              name={name}
              error={errors?.email?.message}
              label="Epost"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange, name } }) => (
            <Input
              className="w-full"
              value={value}
              onChange={onChange}
              name={name}
              error={errors?.password?.message}
              type="password"
              label="Passord"
            />
          )}
        />
        <Button type="submit" className="mt-1 w-full" loading={loading}>
          Logg inn
        </Button>
      </form>
      <div className="my-2 flex h-px w-full items-center justify-center bg-neutral-20">
        <span className="text-par-s-reg bg-white px-2 text-neutral-50">
          Eller
        </span>
      </div>
      <FacebookButton />
    </div>
  );
};

export default LoginForm;
