import React, { useEffect, useState } from "react";
import Modal from "../login/Modal";
import Auth from "../login/Auth";
import CloudSaves from "../knittingpreview/CloudSaves";
import { backendApiAddress } from "../login/utils/backendApi";
import { methodData } from "../httpRequests";
import { Util } from "../static/util";
import Profile from "./Profile";
import Button from "../login/components/ui/Button/Button";

function Header(props: any) {
  const [showLogin, setShowLogin] = useState(false);
  const [showCloudSaves, setShowCloudSaves] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [name, setName] = useState("");

  useEffect(() => {
    const baseURL = `${backendApiAddress}/api`;
    methodData(`${baseURL}/me`, {}, "GET", async (it: any) => {
      return await it.json();
    }).then((it: any) => {
      setIsLoggedIn(it?.first_name ?? false);
      setName(it?.first_name);
    });
  }, [showLogin, showCloudSaves]);

  return (
    <>
      <Modal
        open={showLogin}
        handleClose={() => {
          setShowLogin(false);
        }}
      >
        <Auth
          handleClose={() => {
            setShowLogin(false);
          }}
        />
      </Modal>
      {showCloudSaves && (
        <>
          <div
            onClick={() => {
              setShowCloudSaves(false);
            }}
            style={{
              position: "absolute",
              height: "100vh",
              width: "100vw",
              zIndex: 1,
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              height: "100vh",
              width: "450px",
              left: "calc(100vw - 450px)",
              pointerEvents: "none",
              zIndex: 1,
            }}
          >
            <CloudSaves
              handleClose={() => {
                setShowCloudSaves(false);
              }}
              post={() => {}}
              name={name}
            />
          </div>
        </>
      )}
      <div className="mx-auto grid w-full max-w-screen-2xl grid-cols-3 items-center justify-between px-4 md:px-6">
        <div className="flex items-center gap-x-5 justify-self-start">
          <a
            className="hidden cursor-pointer text-lg sm:block font-normal"
            href="https://shop.woolit.no/product/yarn"
          >
            Garn
          </a>
          <a
            className="hidden cursor-pointer text-lg sm:block font-normal"
            href="https://shop.woolit.no/product/needle"
          >
            Pinner
          </a>
        </div>
        <div className="flex items-center justify-self-center">
          <a href={"https://woolit.no/"}>
            <img src="ui/logo512.png" style={{ height: "20px" }}></img>
          </a>
        </div>
        <div className="flex items-center justify-self-end gap-x-5">
          {isLoggedIn && (
            <Profile
              name={name}
              onClick={() => {
                setShowCloudSaves(true);
              }}
              saving={props.saving}
            />
          )}
          {isLoggedIn === false && (
            <button
              onClick={() => {
                setShowLogin(true);
              }}
            >
              <div
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#d5ce8a",
                  height: "40px",
                  width: "120px",
                  display: "flex",
                }}
              >
                <p style={{ margin: "auto", fontWeight: "500" }}>Logg inn</p>
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
