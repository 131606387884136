import { SweaterPart } from "../SweaterPart";
import { Settings } from "../static/settings";
import { Pattern } from "../Pattern";
import { DrawTypes, SweaterPartAreaGroup } from "../enums";
import { mixColors } from "../knittingpreview/colorutil";
import Drawable from "../Drawable";
import { Util } from "../static/util";
import { colorsScene, getSweaterParts } from "../knittingpreview/scene";
import { Global } from "../static/global";

let shirt_uv: HTMLImageElement;
export let prevSetupDrawable: Drawable | undefined;

let grid: any[][];
let last_previews: any[][] = [];

export let hasLoadedImages: boolean;

export function setHasLoadedImages(newValue: boolean) {
  hasLoadedImages = newValue;
}

export let lastVisitedBucket: any = {};

function make2DArray(x: number, y: number, fillWith: any = -2) {
  return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
}

//For redo/undo. Drawing should use draw()
export function setGrid2(newValue: any) {
  grid = newValue;
}

export function getGrid() {
  //return [...grid] // New reference for SetGridSlow
  if (!grid) return undefined;
  const shallowGrid = [];
  for (let innerGrid of grid) {
    shallowGrid.push([...innerGrid]);
  }
  return shallowGrid;
}

function loadImages() {
  let waitForLoad = [];

  shirt_uv = new Image(4096, 4096);
  shirt_uv.src = `shirt/diffuse_sweater_${Global._shirtType}.png`;
  waitForLoad.push(shirt_uv);

  return waitForLoad;
}

export type drawSelectionProps = {
  drawables: Drawable[];
  pattern: Pattern;
  x: any;
  y: any;
  gridHTML: HTMLDivElement;
  drawType?: DrawTypes;
  drawableInGrid?: Drawable;
  preview?: boolean;
  gap?: number;
  mirror?: number;
  warningOverlay?: any;
  repeat?: boolean;
  moveInfo?: any;
};

export function drawBucket({
  drawables,
  x,
  y,
  pattern,
  drawableInGrid,
  gridHTML,
  gap,
  repeat,
  preview,
  warningOverlay,
}: drawSelectionProps) {
  const drawable = drawables[0];
  const isSelected = drawable === drawableInGrid;

  if (!isSelected && preview) {
    return;
  }

  const [dxCross, dyCross] = crossAlign(drawable, drawableInGrid!);
  x += dxCross;
  y += dyCross;

  const dx = drawable.dx;
  const dy = drawable.dy;

  if (dx > 0 || dy > 0) {
    throw new Error("Not supported here, needs fix");
  }

  const visited: { [point: string]: boolean } = preview
    ? lastVisitedBucket
    : {};
  const newColor = pattern.grid[0][0];
  const points: number[][] = [];
  for (let _x = x % gap!; _x < drawable.sizeX; _x += gap!) {
    if (!repeat && _x !== x) continue;
    if (!drawable.isMask(_x, y)) continue;
    const matchColor = drawable.grid[y][_x];
    points.push([_x, y, matchColor]);
    if (drawable !== drawableInGrid) continue;
    updateGridHTML(
      _x,
      y,
      newColor,
      gridHTML,
      true,
      [true, true, true, true],
      warningOverlay
    );
  }

  while (points.length !== 0) {
    const [x_, y_, matchColor] = points.pop()!;
    if (
      x_ < 0 ||
      y_ < 0 ||
      x_ >= drawable.sizeX + dx ||
      y_ >= drawable.sizeY + dy ||
      Util.point(x_, y_) in visited
    )
      continue;
    if (!drawable.isMask(x_, y_)) continue;
    let drawColor = drawable.grid[y_ - dy][x_ - dx];
    if (drawColor !== matchColor) continue;
    if (!preview) {
      drawable.updateGrid(x_ - dx, y_ - dy, newColor);
    }
    visited[Util.point(x_, y_)] = true;
    if (isSelected) {
      updateGridHTML(
        x_,
        y_,
        newColor,
        gridHTML,
        preview!,
        [false, false, false, false],
        warningOverlay
      );
    }
    for (let dy = -1; dy <= 1; dy++) {
      for (let dx = -1; dx <= 1; dx++) {
        if (dx !== 0 && dy !== 0) continue;
        if (dx === 0 && dy === 0) continue;
        points.push([x_ + dx, y_ + dy, matchColor]);
      }
    }
  }
  lastVisitedBucket = visited;
}

export function drawSelection({
  drawables,
  pattern,
  x,
  y,
  gridHTML,
  drawType,
  drawableInGrid,
  preview = false,
  gap = 0,
  mirror,
  warningOverlay,
  repeat,
  moveInfo,
}: drawSelectionProps) {
  const props = {
    drawables: drawables,
    pattern: pattern,
    x: x,
    y: y,
    gridHTML: gridHTML,
    drawType: drawType,
    drawableInGrid: drawableInGrid,
    preview: preview,
    gap: gap,
    mirror: mirror,
    warningOverlay: warningOverlay,
    repeat: repeat,
    moveInfo: moveInfo,
  };
  clearPreview();
  const parity = pattern.grid[0].length % 2 === 1 ? 1 : 2;
  const sizeX = drawables[0].sizeX;
  const dx = drawables[0].dx;
  switch (mirror) {
    case 0:
      return _drawSelection(props);
    case 1: {
      _drawSelection(props);
      props.x = sizeX + dx * 2 - (props.x + parity);
      _drawSelection(props);
      break;
    }
    case 2: {
      let sweaterPart = drawableInGrid as SweaterPart;
      let otherSide = sweaterPart.findOppositePart(getSweaterParts());
      props.drawables = [otherSide];
      const armsDifferently =
        sweaterPart.areaGroup() === SweaterPartAreaGroup.Arms &&
        Settings.mirrorArmsDifferently;
      if (armsDifferently) {
        props.x = sizeX + dx * 2 - (props.x + parity);
      }
      _drawSelection(props);

      props.drawables = [sweaterPart];
      if (armsDifferently) {
        props.x = sizeX + dx * 2 - (props.x + parity);
      }
      _drawSelection(props);
      break;
    }
    case 3: {
      let sweaterPart = drawableInGrid as SweaterPart;
      let otherSide = sweaterPart.findOppositePart(getSweaterParts());
      props.drawables = [otherSide];
      _drawSelection(props);
      props.x = sizeX + dx * 2 - (props.x + parity);
      _drawSelection(props);

      props.drawables = [sweaterPart];
      _drawSelection(props);
      props.x = sizeX + dx * 2 - (props.x + parity);
      _drawSelection(props);
      break;
    }
  }
}

export function _drawSelection({
  drawables,
  pattern,
  x,
  y,
  gridHTML,
  drawType,
  drawableInGrid,
  preview = false,
  gap = 0,
  warningOverlay = undefined,
  repeat = false,
  moveInfo = null,
}: drawSelectionProps) {
  const anchor = pattern.anchor();
  const length = pattern.length();
  const anchorX = anchor[0];
  const anchorY = anchor[1];
  const lengthX = length[0];
  const lengthY = length[1];
  switch (drawType) {
    case DrawTypes.Pattern:
    case DrawTypes.Brush: {
      for (let drawable of drawables) {
        if (repeat) {
          drawRepeat(
            drawable,
            x - anchorX,
            y - anchorY,
            x - anchorX + lengthX,
            y - anchorY + lengthY,
            pattern,
            drawableInGrid!,
            gridHTML,
            preview,
            gap,
            warningOverlay
          );
        } else {
          drawBrush(
            drawables[0],
            false,
            x - anchorX,
            y - anchorY,
            x - anchorX + lengthX,
            y - anchorY + lengthY,
            drawableInGrid!,
            pattern,
            gridHTML,
            preview,
            warningOverlay
          );
          break;
        }
      }
      const pos = x - anchorX - drawableInGrid!.dx;
      let target = drawableInGrid!.sizeX / 2;
      const m = pattern.sizeX + gap;
      if (gap > 0) {
        target += Math.floor(gap / 2);
      }
      return (
        Util.mod(pos - target, m) === 0 ||
        Util.mod(pos - target, m) === Math.ceil(pattern.sizeX / 2)
      );
    }
    case DrawTypes.Bucket: {
      for (let drawable of drawables) {
        const props = {
          drawables: [drawable],
          pattern: pattern,
          x: x,
          y: y,
          drawableInGrid: drawableInGrid,
          gridHTML: gridHTML,
          preview: preview,
          warningOverlay: warningOverlay,
          gap: gap,
          repeat: repeat,
        };
        drawBucket(props);
      }
      break;
    }
    case DrawTypes.Move: {
      const { moveMode, mouseUp, startPosY } = moveInfo;
      const gap = startPosY - y;
      const drawable = drawables[0];
      // Preview
      if (preview) {
        for (let _x = 0; _x < drawable.sizeX; _x += 1) {
          if (!drawable.isMask(_x, y)) continue;
          updateGridHTML(
            _x,
            y,
            drawable.grid[y][_x],
            gridHTML,
            preview,
            [moveMode === -1, false, moveMode === 1, false],
            warningOverlay
          );
        }
        break;
      }

      // Draw
      for (let drawable of drawables) {
        const updates = [];
        // Do updates at the end to make
        // them not affect one another
        for (let _x = 0; _x < drawable.sizeX; _x += 1) {
          const startY = moveMode === 1 ? 0 : y;
          const endY = moveMode === 1 ? y : drawable.sizeY;
          for (let _y = startY; _y < endY; _y++) {
            const newY = _y + gap;
            let newColor = 0;
            if (newY >= 0 && newY < drawable.sizeY) {
              newColor = drawable.grid[newY][_x];
            }
            if (mouseUp) {
              updates.push([_x, _y, newColor]);
            }
            if (drawable !== drawableInGrid) continue;
            if (drawable.isMask(_x, _y)) {
              updateGridHTML(
                _x,
                _y,
                newColor,
                gridHTML,
                !mouseUp,
                [false, false, false, false],
                warningOverlay
              );
            }
          }
        }
        for (let update of updates) {
          const [x, y, color] = update;
          drawable.updateGrid(x, y, color);
        }

        // fill gaps
        for (let _x = 0; _x < drawable.sizeX; _x += 1) {
          const startY = moveMode === 1 ? y : startPosY;
          const endY = moveMode === 1 ? startPosY : y;
          for (let _y = startY; _y < endY; _y++) {
            if (mouseUp) {
              drawable.updateGrid(_x, _y, 0);
            }
            if (drawable !== drawableInGrid) continue;
            if (drawable.isMask(_x, _y)) {
              updateGridHTML(
                _x,
                _y,
                0,
                gridHTML,
                !mouseUp,
                [false, false, false, false],
                warningOverlay
              );
            }
          }
        }
      }
      break;
    }
  }
}

/*export function addSelection(sweaterPart: any, x: any, y: any) {
    let selectedX = x
    let selectedY = y
    draw(sweaterPart, selectedX - 1, selectedY - 1, selectedX + 2, selectedY + 2, true)
}

/*export function removeSelection(sweaterPart: any) {
    let selectedX = state.selectedTilePos[0]
    let selectedY = state.selectedTilePos[1]
    let temp = [...state.selectedTilePos]
    state.selectedTilePos = [-999, -999]
    draw(sweaterPart, selectedX - 1, selectedY - 1, selectedX + 2, selectedY + 2)
    state.selectedTilePos = [...temp]
}*/

export function clearGrid() {
  grid = make2DArray(Global.gridSizeX, Global.gridSizeY);
}

function calculateGridSize(sweaterPart: any) {
  const dx = sweaterPart.dx;
  const dy = sweaterPart.dy;
  const sizeX = sweaterPart.sizeX;
  const sizeY = sweaterPart.sizeY;

  const startX = dx;
  const startY = dy;
  const endX = sizeX + dx;
  const endY = sizeY + dy;

  let highestX = 0;
  let highestY = 0;
  let lowestX = 99;
  let lowestY = 99;
  for (let x = startX; x < endX; x += 1) {
    for (let y = startY; y < endY; y += 1) {
      if (sweaterPart.isMask(x, y)) {
        //Do four corner checks
        if (x > highestX) {
          highestX = x;
        }
        if (y > highestY) {
          highestY = y;
        }
        if (x < lowestX) {
          lowestX = x;
        }
        if (y < lowestY) {
          lowestY = y;
        }
      }
    }
  }
  const diffx = lowestX - dx;
  if (diffx !== 0) {
    console.log(
      "Error: Missaligned sweaterpart, expected 0, but got x:" + diffx
    );
  }
  const diffy = lowestY - dy;
  if (diffy !== 0) {
    //console.log("Error: Missaligned sweaterpart, expected 0, but got y:" + diffy)
  }
  highestX += dx + 1;
  highestY += dy + 1;
  return [highestX, highestY];
}

function setupEditPattern(editPattern: Pattern) {
  const dx = editPattern.dx;
  const dy = editPattern.dy;

  const sizeX = editPattern.sizeX;
  const sizeY = editPattern.sizeY;

  Global.gridSizeX = sizeX + dx * 2;
  Global.gridSizeY = sizeY + dy * 2;
  //Should be performed if:
  //* Size is changed
  //* Draw is performed on a new selectedSweaterPart
  //Else it would cause unneccesary computing
  clearGrid();
}

function setupSweaterPart(sweaterPart: SweaterPart) {
  const gridSizes = calculateGridSize(sweaterPart);

  Global.gridSizeX = gridSizes[0];
  Global.gridSizeY = gridSizes[1];
  //Should be performed if:
  //* Size is changed
  //* Draw is performed on a new selectedSweaterPart
  clearGrid();
}

function setupDrawable(drawable: Drawable) {
  prevSetupDrawable = drawable;
  if (drawable.isSweaterPart()) {
    setupSweaterPart(drawable as SweaterPart);
  } else {
    setupEditPattern(drawable as Pattern);
  }
}

function updateGridHTML(
  x: number,
  y: number,
  newValue: number,
  gridHTML: HTMLDivElement | undefined,
  preview: boolean,
  dirs: any[],
  warningOverlay: boolean,
  isClearPreview: boolean = false
) {
  const borderColorOutlineRGBstring = Settings.borderColorOutlineRGBstring;
  const borderColorOutlineSnapRGBstring =
    Settings.borderColorOutlineSnapRGBstring;
  const borderPosColors = [
    "borderTopColor",
    "borderLeftColor",
    "borderBottomColor",
    "borderRightColor",
  ] as const;
  const borderColor = Util.calculateBorderColor(
    x,
    y,
    Util.colorIndexToColor(colorsScene, newValue),
    newValue < -1,
    warningOverlay
  );
  const hasUpdatedBorder = !dirs.every((it) => it === false);

  const oldValue = grid[y][x];
  // Draw/preview new color
  if (oldValue !== newValue) {
    grid[y][x] = newValue;
    if (gridHTML) {
      const gridCell = gridHTML.children[y].children[x] as HTMLDivElement;

      let warningColor = undefined;
      if (warningOverlay) {
        warningColor = Global.warningDescriptionInfo(grid, x, y)?.color;
      }
      const colorDraw = Util.calculateGridColor(
        x,
        y,
        colorsScene,
        newValue,
        warningOverlay,
        warningColor
      );
      const color = preview ? mixColors([colorDraw, "#ffffff"]) : colorDraw;
      gridCell.style.backgroundColor = color;

      for (let borderPosColor of borderPosColors) {
        const isOutline =
          gridCell.style[borderPosColor] === borderColorOutlineRGBstring ||
          gridCell.style[borderPosColor] === borderColorOutlineSnapRGBstring;
        if (!isOutline) {
          gridCell.style[borderPosColor] = borderColor;
        }
      }
    }
  }
  // Draw/preview brush outline
  if (hasUpdatedBorder && gridHTML) {
    const gridCell = gridHTML.children[y].children[x] as HTMLDivElement;
    const borderOutlineColor = "#000000";
    for (let n = 0; n < 4; n++) {
      const borderPosColor = borderPosColors[n];
      const isOutline =
        gridCell.style[borderPosColor] === borderColorOutlineRGBstring ||
        gridCell.style[borderPosColor] === borderColorOutlineSnapRGBstring;
      const isPatternOutline = dirs[n];
      if (!isOutline && isPatternOutline) {
        if (!isClearPreview) {
          gridCell.style[borderPosColor] = borderOutlineColor;
        } else {
          gridCell.style[borderPosColor] = borderColor;
        }
      }
    }
  }
  if (preview) {
    last_previews.push([x, y, oldValue, gridHTML, dirs, warningOverlay]);
  }
  //Ugly: Necessary to clear eraser outline
  if (!preview && hasUpdatedBorder && !isClearPreview) {
    last_previews.push([x, y, newValue, gridHTML, dirs, warningOverlay]);
  }
}
function crossAlign(drawable: Drawable, drawableInGrid: Drawable) {
  const isSweater = drawable.isSweaterPart();
  if (isSweater) {
    const sweaterPart = drawable as SweaterPart;
    const selectedSweaterPart = drawableInGrid as SweaterPart;
    if (selectedSweaterPart.areaGroup() !== sweaterPart.areaGroup()) {
      const [_dx, _dx2] = [sweaterPart.dx, selectedSweaterPart.dx];
      const [_dy, _dy2] = [sweaterPart.dy, selectedSweaterPart.dy];
      const dxDiff = _dx - _dx2;
      const dyDiff = _dy - _dy2;

      const [_sizeX, _sizeX2] = [sweaterPart.sizeX, selectedSweaterPart.sizeX];
      const sizeXDiff = _sizeX - _sizeX2;
      const mirrorXDiff = sizeXDiff / 2;

      const topArmYDiff = sweaterPart.connectY - selectedSweaterPart.connectY;

      const dxCross = dxDiff + mirrorXDiff;
      const dyCross = dyDiff + topArmYDiff;
      return [dxCross, dyCross];
    }
  }
  return [0, 0];
}

function drawRepeat(
  drawable: Drawable,
  startX: number = 0,
  startY: number = 0,
  endX: number = Infinity,
  endY: number = Infinity,
  pattern: Pattern,
  drawableInGrid: Drawable,
  gridHTML: HTMLDivElement,
  preview: boolean,
  gap: number,
  warningOverlay: any
) {
  const isSelected = drawable === drawableInGrid;
  if (!isSelected && preview) return;

  const [dxCross, dyCross] = crossAlign(drawable, drawableInGrid);

  startX += dxCross;
  startY += dyCross;
  endX += dxCross;
  endY += dyCross;

  let xMod = startX;
  let xLen = endX - startX;

  if (Math.sign(gap) === -1 && -gap >= xLen) {
    return;
  }

  const dx = drawable.dx;
  const dy = drawable.dy;
  const sizeY = drawable.sizeY;

  startX = dx;
  endX = dx + drawable.grid[0].length;

  endY = Math.min(sizeY + dy, endY);

  for (let x = startX; x < endX; x += 1) {
    for (let y = startY; y < endY; y += 1) {
      if (y < dy) {
        continue;
      }
      const patternY = y - startY;
      let patternX = Util.mod(x - xMod, xLen + gap);
      if (gap < 0) {
        patternX += Math.floor(-gap / 2);
      }
      if (patternX >= xLen) {
        continue;
      }

      let drawColor = drawable.grid[y - dy][x - dx];
      const brushColor = pattern!!.grid[patternY][patternX];
      if (brushColor !== -1) {
        if (preview && drawable.isMask(x, y)) {
          drawColor = brushColor;
        } else if (!preview) {
          drawable.updateGrid(x - dx, y - dy, brushColor);
          drawColor = brushColor;
        }
        if (isSelected && drawable.isMask(x, y)) {
          //pattern!!.getOutlines(patternX, patternY)
          const up = patternY === 0;
          const left = patternX === 0;
          const down = patternY === pattern.sizeY - 1;
          const right = patternX === pattern.sizeX - 1;
          const outlines = pattern.brush
            ? [up, left, down, right]
            : [false, false, false, false];
          updateGridHTML(
            x,
            y,
            drawColor,
            gridHTML,
            preview,
            outlines,
            warningOverlay
          );
        }
      }
    }
  }
}

export function clearPreview() {
  if (!last_previews) return;
  lastVisitedBucket = {};
  // Opposite order, or else overlapping drawn areas will override incorrectly
  for (let n = last_previews.length - 1; n >= 0; n--) {
    const last_preview_info = last_previews[n];
    const x = last_preview_info[0];
    const y = last_preview_info[1];
    const oldBrushColor = last_preview_info[2];
    const gridHTML = last_preview_info[3];
    const dirs = last_preview_info[4];
    const warningOverlay = last_preview_info[5];
    updateGridHTML(
      x,
      y,
      oldBrushColor,
      gridHTML,
      false,
      dirs,
      warningOverlay,
      true
    );
  }
  last_previews = [];
}

export function drawGrid(
  drawable: Drawable,
  startX: number = 0,
  startY: number = 0,
  endX: number = Infinity,
  endY: number = Infinity
) {
  clearPreview();

  setupDrawable(drawable);

  const dx = drawable?.dx ?? 0;
  const dy = drawable?.dy ?? 0;

  const sizeX = drawable?.sizeX ?? 0;
  const sizeY = drawable?.sizeY ?? 0;

  endX = Math.min(sizeX + dx, endX);
  endY = Math.min(sizeY + dy, endY);

  for (let x = startX; x < endX; x += 1) {
    for (let y = startY; y < endY; y += 1) {
      if (y < dy || x < dx) {
        continue;
      }
      if (drawable.isMask(x, y)) {
        let drawColor = drawable.grid[y - dy][x - dx];
        updateGridHTML(
          x,
          y,
          drawColor,
          undefined,
          false,
          [false, false, false, false],
          false
        );
      }
    }
  }
}

export function drawBrush(
  drawable: Drawable,
  loadGrid: boolean,
  startX: number = 0,
  startY: number = 0,
  endX: number = Infinity,
  endY: number = Infinity,
  drawableInGrid: Drawable,
  pattern?: Pattern,
  gridHTML?: HTMLDivElement,
  preview: boolean = false,
  warningOverlay: any = undefined
) {
  const isSelected = drawable === drawableInGrid;
  if (!isSelected && preview) return;

  const dx = drawable.dx;
  const dy = drawable.dy;

  const sizeX = drawable.sizeX;
  const sizeY = drawable.sizeY;

  endX = Math.min(sizeX + dx, endX);
  endY = Math.min(sizeY + dy, endY);

  for (let x = startX; x < endX; x += 1) {
    for (let y = startY; y < endY; y += 1) {
      if (y < dy || x < dx) {
        continue;
      }
      if (drawable.isMask(x, y)) {
        let drawColor = drawable.grid[y - dy][x - dx];
        if (pattern) {
          const patternY = y - startY;
          const patternX = x - startX;
          const brushColor = pattern.grid[patternY][patternX];
          if (brushColor !== -1 || pattern?.eraser) {
            if (preview && isSelected) {
            } else if (!preview) {
              drawable.updateGrid(x - dx, y - dy, brushColor);
            }
            drawColor = brushColor;
            if (isSelected) {
              const up = patternY === 0;
              const left = patternX === 0;
              const down = patternY === pattern.sizeY - 1;
              const right = patternX === pattern.sizeX - 1;
              const outlines = pattern.brush
                ? [up, left, down, right]
                : [false, false, false, false];
              updateGridHTML(
                x,
                y,
                drawColor,
                gridHTML,
                preview,
                outlines,
                warningOverlay
              );
            }
          }
        }
      }
    }
  }
}

export function onLoadImages(drawable: Drawable, setGrid: any) {
  let canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = 4096;
  canvas.height = 4096;
  let ctx = canvas.getContext("2d")!!;
  ctx.drawImage(shirt_uv, 0, 0);
  Global.imageData = ctx.getImageData(0, 0, 4096, 4096);

  _loadGrid(drawable, setGrid);

  hasLoadedImages = true;
}

function _loadGrid(drawable: Drawable, setGrid: any) {
  drawGrid(drawable);
  setGrid(getGrid());
}

export function loadGrid(drawable: Drawable, setGrid: any) {
  if (!hasLoadedImages && drawable.isSweaterPart()) {
    let waitForLoad = loadImages();
    for (let image of waitForLoad) {
      image.onload = () => {
        waitForLoad.pop();
        if (waitForLoad.length === 0) {
          onLoadImages(drawable, setGrid);
        }
      };
    }
  } else {
    _loadGrid(drawable, setGrid);
  }
}
