import React from "react";
import cn from "classnames";
import FacebookIconRound from "../../../components/icons/FacebookIconRound";
import { backendApiAddress } from "../../../utils/backendApi";

type Props = {
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  className?: string;
};

const FacebookButton: React.FC<React.PropsWithChildren<Props>> = ({
  target = "_blank",
  className,
}) => {
  return (
    <a
      href={`https://www.facebook.com/v8.0/dialog/oauth?client_id=352549798431521&scope=public_profile,email&redirect_uri=${backendApiAddress}/api/login/facebook_return_3d`}
      target={target}
      className={cn(
        "flex items-center justify-center gap-x-2 whitespace-nowrap rounded bg-[#1877F2] p-4 font-facebook font-semibold leading-none text-white",
        className
      )}
    >
      <FacebookIconRound />
      <span>Continue with Facebook</span>
    </a>
  );
};

export default FacebookButton;
