import { Pattern } from "../Pattern";
import { hexToRgb, rgbToHex } from "../knittingpreview/colorutil";
import { Settings } from "./settings";

//Help functions. E.x. math
export class Util {
  static colorIndexToColor(colors: string[], colorIndex: number) {
    let color = "";
    if (colorIndex === -2) {
      // Kind of a bad solution. But these must be non-hex colors, since
      // this information is used to determine if the color originated from === -2
      // Edit: Im not sure if this is longer a issue
      color = "#F9F5F2";
    } else if (colorIndex === -1) {
      color = "#ffffff";
      //return (x + y) % 2 === 1 ? "#f0f0f0" : "#e0e0e0";
    } else {
      color = colors[colorIndex];
    }
    return color;
  }
  static calculateGridColor(
    x: any,
    y: any,
    colors: any,
    colorIndex: any,
    warningOverlay: any,
    warningColor: any
  ) {
    if (warningColor) return warningColor;
    const color = this.colorIndexToColor(colors, colorIndex);
    //if (warningOverlay) return mixColors([color, "#000000"])
    return color;
    //return "#f9f5f2"
  }
  static calculateBorderColor(
    x: number,
    y: number,
    _color: any,
    isOutside: boolean = false,
    warningOverlay: boolean = false
  ) {
    let color = "";
    if (isOutside) {
      color = "#F9F5F2";
    } else {
      const rgb = hexToRgb(_color);
      /*if (rgb.r + rgb.g + rgb.b > 700 && rgb.r + rgb.g + rgb.b < 730) {
                return "#ffffff"
            }*/
      if (rgb.r + rgb.g + rgb.b >= 730) {
        color = "#f0f0f0";
      } else {
        const lightenAmount = 0.75;
        rgb.r = rgb.r * (1 - lightenAmount) + 255 * lightenAmount;
        rgb.g = rgb.g * (1 - lightenAmount) + 255 * lightenAmount;
        rgb.b = rgb.b * (1 - lightenAmount) + 255 * lightenAmount;
        color = rgbToHex(rgb);
      }
    }
    //if (warningOverlay) return mixColors([color, "#000000"])
    return color;
  }

  static clamp(num: number, min: number, max: number) {
    return Math.max(min, Math.min(max, num));
  }

  static emptyPattern() {
    const pattern = new Pattern(
      this.make2DArray(Settings.maxPatternWidth, Settings.maxPatternHeight, -1),
      12,
      12
    );
    return pattern;
  }

  static patternIsEmpty(grid: number[][]) {
    const colors = new Set(grid.flat(1));
    colors.delete(-1);
    return colors.size === 0;
  }

  static make2DArray(x: number, y: number, fillWith: any = -2) {
    return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
  }

  static colorToName(color: string) {
    const index = Settings.colorsAll.indexOf(color);
    if (index === -1) {
      throw new Error("Could not retrieve color name of: " + color);
    }
    return Settings.colorsAllNames[index];
  }

  static patternIsMonocolored(pattern: Pattern) {
    const uniqueColors = new Set(pattern.grid.flat(1));
    uniqueColors.delete(-1);
    return uniqueColors.size === 1;
  }

  static sum(array: number[]) {
    return array.reduce((a, b) => a + b, 0);
  }

  static mod(n: number, m: number) {
    return ((n % m) + m) % m;
  }

  //string representation of vector2. Thus can be used as a key in dictionaries
  static point(x: number, y: number) {
    return x + "," + y;
  }

  static mergeDicts(dicts: any) {
    const mergedDict: { [id: string]: number } = {};
    for (let dict of dicts) {
      for (let key of Object.keys(dict)) {
        if (!(key in mergedDict)) {
          mergedDict[key] = 0;
        }
        mergedDict[key] += dict[key];
      }
    }
    return mergedDict;
  }

  static titleCase(str: string) {
    if (!str) return "undefined"
    var splitStr = str.toLowerCase().replace("_", " ").split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }

  static makeGrid(size: number) {
    let grid: number[][] = [];
    for (let y = 0; y < size; y++) {
      let grid_int: [] = [];
      grid.push(grid_int);
      for (let x = 0; x < size; x++) {
        grid[y].push(0);
      }
    }
    return grid;
  }


  static isKey(pathName: string) {
    if (pathName.length !== 8) return false;
    return pathName.match(/^[A-Za-z0-9]*$/) !== null;
  }

  static sortPriority(array: any[], prioritizedItem: any){
    return array.sort((a, _) => 0.5 - Number(a === prioritizedItem))
  }
}
