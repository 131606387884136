export const backendApiAddress = (() => {
  const local = "http://localhost:2795";
  const prod = "https://woolit.no";
  const staging = "https://staging.woolit.no";

  // server side
  if (process.env.BACKEND_ENV == undefined) { //EDIT
    return prod;
    // return local;
  }
  if (process.env.BACKEND_ENV == "local") {
    return prod;
    // return local;
  }
  if (process.env.BACKEND_ENV == "staging") {
    return staging;
  }
  if (process.env.BACKEND_ENV == "prod") {
    return prod;
  }

  // client side
  if (typeof window === "undefined") {
    return prod;
  }

  if (window.location.hostname === "localhost") {
    // return local;
    return prod;
  }
  if (window.location.hostname.endsWith(".dev.woolit.no")) {
    return staging;
  }
  if (window.location.hostname.endsWith(".test.iterate.no")) {
    return staging;
  }
  if (window.location.hostname.endsWith("testshop.woolit.no")) {
    return staging;
  }
  if (window.location.hostname.endsWith(".app.woolit.no")) {
    return prod;
  }
  if (window.location.hostname.endsWith("shop.woolit.no")) {
    return prod;
  }
  if (
    window.location.hostname.endsWith("snap0.woolit-shop.iterate.iterapp.no")
  ) {
    return prod;
  }

  // default
  return prod;
})();
