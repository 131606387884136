import { useEffect } from "react";
import { DrawTypes } from "../enums";
import { Pattern } from "../Pattern";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Util } from "../static/util";
import { Tooltip } from "@mui/material";
import GridButton from "../components/GridButton";
import NumberInput from "../components/NumberInput";
import React from "react";

function PatternTools(props: any) {
  useEffect(() => {
    AddPattern();
  }, []);

  function CanEdit() {
    return props.patternID !== -1;
  }

  function AddPattern() {
    if (props.selectedEditPattern) return;
    props.setSelectedEditPattern(Util.emptyPattern());
    props.setDrawType(DrawTypes.Brush, 1);
  }

  return (
    <div
      style={{
        marginTop: "15px",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <div
        style={{
          marginTop: "15px",
          marginBottom: props.selectedEditPattern ? "20px" : "0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title="Add Pattern" disableInteractive>
          <button
            className="gridButton noBackground"
            onClick={() => {
              AddPattern();
            }}
            style={{
              margin: "0px",
              minWidth: "100px",
              maxWidth: "100px",
              height: "100px",
              boxShadow:
                "0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 2px 0 rgba(0,0,0,0.19)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                alt="Add Pattern"
                src={props.selectedEditPattern ? "ui/add2.png" : "ui/add.png"}
                style={{
                  height: "40px",
                  imageRendering: "crisp-edges",
                  opacity: props.selectedEditPattern ? 0.1 : 1,
                  margin: "auto",
                  marginTop: "15px",
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
              ></img>
              <p
                style={{
                  color: props.selectedEditPattern ? "lightGray" : "#86a07a",
                  marginTop: "0px",
                  fontSize: "16px",
                  fontWeight: 300,
                }}
              >
                Pattern
              </p>
            </div>
          </button>
        </Tooltip>
        <div
          className="showScrollbar"
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: "15px",
            marginRight: "10px",
            overflow: "scroll",
            overflowX: "hidden",
            overflowY: "auto",
            height: "120px",
          }}
        >
          {props.patterns.map((pattern: any, index: any) => (
            <GridButton
              title={"Select: Pattern " + (index + 1)}
              onClick={() => {
                const patternID = index;
                if (props.patternID !== patternID) {
                  props.setDrawType(DrawTypes.Pattern, patternID);
                } else {
                  props.setDrawType(DrawTypes.Brush, 1);
                }
              }}
              isSelected={props.patternID === index}
              key={index}
              pattern={pattern}
              colors={props.colors}
            />
          ))}
        </div>
        <Tooltip title="Edit Pattern" disableInteractive>
          <button
            className="gridButton noBackground"
            onClick={() => {
              if (!CanEdit()) return;
              if (
                props.patternIsUnsaved &&
                !window.confirm(
                  "Are you sure you want to close without saving?"
                )
              ) {
                return;
              }
              const patternToEditRef = props.patterns[props.patternID];
              const patternToEdit = new Pattern(
                patternToEditRef.copyGrid(),
                patternToEditRef.sizeX,
                patternToEditRef.sizeY
              );
              patternToEdit.id = props.patternID;
              patternToEdit.fillGrid();
              if (props.selectedEditPattern) {
                props.setRefreshPattern(true);
              }
              props.setSelectedEditPattern(patternToEdit);
              props.setDrawType(DrawTypes.Brush, 1);
            }}
            style={{
              margin: "0px",
              minWidth: "100px",
              maxWidth: "100px",
              height: "100px",
              boxShadow:
                "0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 2px 0 rgba(0,0,0,0.19)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                alt="Edit pattern"
                src={CanEdit() ? "ui/edit.png" : "ui/edit2.png"}
                style={{
                  height: "40px",
                  imageRendering: "crisp-edges",
                  color: "black",
                  opacity: CanEdit() ? 1 : 0.1,
                  margin: "auto",
                  marginTop: "15px",
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
              ></img>
              <p
                style={{
                  color: CanEdit() ? "#86a07a" : "lightGray",
                  marginTop: "0px",
                  fontSize: "16px",
                  fontWeight: 300,
                }}
              >
                Edit
              </p>
            </div>
          </button>
        </Tooltip>
      </div>
    </div>
  );
}

export default PatternTools;
