import React from "react";
import { Settings } from "../static/settings";
import { Util } from "../static/util";
import { Tooltip } from "@mui/material";

function GridCell(props: any) {
  function calculateGridColor() {
    const gridColor = Util.calculateGridColor(
      props.x,
      props.y,
      props.colors,
      props.colorIndex,
      props.warningOverlay,
      props.warningColor
    );
    return gridColor;
  }
  function calculateBorderColor() {
    const color = Util.colorIndexToColor(props.colors, props.colorIndex);
    const borderColor = Util.calculateBorderColor(
      props.x,
      props.y,
      color,
      props.colorIndex < -1,
      props.warningOverlay
    );
    return borderColor;
  }
  function borderEdgeColors() {
    const borderColor = calculateBorderColor();
    let borderColorOutline = Settings.borderColorOutline;
    if (props.snap) {
      borderColorOutline = Settings.borderColorOutlineSnap;
    }
    return {
      borderTopColor: props.blackBorders[0] ? borderColorOutline : borderColor,
      borderRightColor: props.blackBorders[1]
        ? borderColorOutline
        : borderColor,
      borderBottomColor: props.blackBorders[2]
        ? borderColorOutline
        : borderColor,
      borderLeftColor: props.blackBorders[3] ? borderColorOutline : borderColor,
    };
  }
  const _div = (
    <div
      className="gridCell"
      onMouseDown={(e: any) => {
        e.preventDefault();
        props.setLastPos([props.x, props.y]);
        props.onMouseOver(props.x, props.y, true, false, false);
      }}
      onMouseUp={(e: any) => {
        e.preventDefault();
        props.onMouseOver(props.x, props.y, false, true, false);
        props.setLastPos([]);
        props.updateUndo();
      }}
      onMouseOver={(e: any) => {
        e.preventDefault();
        let flags = e.buttons !== undefined ? e.buttons : e.which;
        let primaryMouseButtonDown = (flags & 1) === 1;
        props.onMouseOver(
          props.x,
          props.y,
          false,
          false,
          !primaryMouseButtonDown
        );
      }}
      style={{
        height: props.sizeY + "px",
        minWidth: props.sizeX + "px",
        maxWidth: props.sizeX + "px",
        backgroundColor: calculateGridColor(),
        ...borderEdgeColors(),
      }}
    >
      {/* color is used to store the original color (as backgroundColor can be modified by brush/patterns)
    React doesnt update cells modified by cell.style.backgroundColor = newValue */}
    </div>
  );
  return props.warningDescription ? (
    <Tooltip
      title={props.warningDescription}
      componentsProps={{
        tooltip: {
          sx: {
            color: props.warningColor === "#ff0000" ? "white" : "black",
            fontSize: "16px",
            maxWidth: "none",
            backgroundColor: props.warningColor,
          },
        },
      }}
      disableInteractive
    >
      {_div}
    </Tooltip>
  ) : (
    _div
  );
}
export default GridCell;
