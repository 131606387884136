import { useEffect, useState } from "react";
import { DrawTypes, KnitabilityProblem } from "../enums";
import { Settings } from "../static/settings";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SizePopup from "./SizePopup";
import GridButton from "../components/GridButton";
import { Util } from "../static/util";
import React from "react";
import { setKnitability } from "../../store/knitabilityslice";
import { useAppDispatch } from "../../store/hooks";
import NumberInput from "../components/NumberInput";

function Tools(props: any) {
  const [showSizePopup, setShowSizePopup] = useState(false);
  const [showColorsPopup, setShowColorsPopup] = useState(false);

  const dispatch = useAppDispatch();

  function repeatOptions() {
    //return editPatternIsOpen() ? [...(props.repeatOptions as string[]), "Pattern"] : props.repeatOptions as string[]
    return props.repeatOptions as string[];
  }

  function mirrorActive() {
    //Todo: Simplify, fast change
    const brushOrEraserOrBucket =
      props.getDrawType() === DrawTypes.Brush ||
      props.getDrawType() === DrawTypes.Eraser ||
      props.getDrawType() === DrawTypes.Bucket;
    const patternNonRepeat =
      props.getDrawType() === DrawTypes.Pattern && props.repeat === 0;
    return patternNonRepeat || brushOrEraserOrBucket;
  }

  function mirrorOptions() {
    /*return editPatternIsOpen()
            ? [...(props.mirrorOptions as string[]), "Pattern"]
            : (props.mirrorOptions as string[]);*/
    return props.mirrorOptions as string[];
  }

  return (
    <div
      style={{
        margin: "10px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "5px",
          }}
        >
          <div
            className="buttonContainer"
            style={{ margin: "auto", alignSelf: "center" }}
          >
            <GridButton
              small={true}
              size={22}
              title="Brush"
              id="brushButton"
              onClick={() => {
                props.setDrawType(DrawTypes.Brush, 1);
                let showPopup =
                  props.getDrawType() !== DrawTypes.Brush || !showSizePopup;
                setShowSizePopup(showPopup);
                props.setDoIfClickedOutside([
                  () => {
                    setShowSizePopup(false);
                  },
                  () => {
                    return [
                      document.getElementById("showSizePopup"),
                      document.getElementById("brushButton"),
                    ];
                  },
                ]);
              }}
              imageURL={"ui/brush.png"}
              isSelected={props.getDrawType() === DrawTypes.Brush}
            />
            <GridButton
              small={true}
              size={22}
              title="Eraser"
              id="eraserButton"
              onClick={() => {
                props.setDrawType(DrawTypes.Eraser, 1);
                let showPopup =
                  props.getDrawType() !== DrawTypes.Eraser || !showSizePopup;
                setShowSizePopup(showPopup);
                props.setDoIfClickedOutside([
                  () => {
                    setShowSizePopup(false);
                  },
                  () => {
                    return [
                      document.getElementById("showSizePopup"),
                      document.getElementById("eraserButton"),
                    ];
                  },
                ]);
              }}
              imageURL={"ui/eraser.png"}
              isSelected={props.getDrawType() === DrawTypes.Eraser}
            />
            <GridButton
              small={true}
              size={22}
              title="Fill"
              onClick={() => props.setDrawType(DrawTypes.Bucket)}
              imageURL={"ui/bucket.png"}
              isSelected={props.getDrawType() === DrawTypes.Bucket}
            />
          </div>
          <div>
            {showSizePopup && (
              <SizePopup
                setDrawType={props.setDrawType}
                setShowSizePopup={setShowSizePopup}
                drawType={props.getDrawType()}
                marginLeft={
                  props.getDrawType() === DrawTypes.Brush ? "-2.5px" : "37.5px"
                }
                sizes={
                  props.getDrawType() === DrawTypes.Brush
                    ? [1, 2, 3, 4]
                    : [1, 4, 8, 16]
                }
              />
            )}
          </div>
        </div>
        <div
          id="colorsDiv"
          style={{
            display: "flex",
            opacity: "1",
            marginLeft: "10px",
            gap: "5px",
          }}
        >
          <div className="buttonContainer">
            {props.colors.map((color: any, index: any) => (
              <GridButton
                title={"Select: " + Util.colorToName(color)}
                onClick={() => {
                  props.setColorID(index);
                }}
                color={color}
                isSelected={props.colorID === index}
                small={true}
                key={index}
                colors={props.colors}
                colorID={props.colorID}
                setColorID={props.setColorID}
                setColors={props.setColors}
                setShowColorsPopup={setShowColorsPopup}
                popColor={props.colorID === index && showColorsPopup}
              />
            ))}
            <GridButton
              title={"Edit Selected Color"}
              onClick={() => {
                setShowColorsPopup(!showColorsPopup);
                props.setDoIfClickedOutside([
                  () => {
                    setShowColorsPopup(false);
                  },
                  () => {
                    return document.getElementById("colorsDiv");
                  },
                ]);
              }}
              imageURL={"ui/edit2.png"}
              size={20}
              small={true}
            />
          </div>
        </div>
        {/*<div className='buttonContainer'>
                    <button className="gridButton" onClick={() => props.changeMaskSize("S")}>
                        <img src="ui/square.png" style={{ border: "1px solid black", height: "12px" }}></img>
                    </button>
                    <button className="gridButton" onClick={() => props.changeMaskSize("M")}>
                        <img src="ui/square.png" style={{ border: "1px solid black", height: "17px" }}></img>
                    </button>
                    <button className="gridButton" onClick={() => props.changeMaskSize("L")}>
                        <img src="ui/square.png" style={{ border: "1px solid black", height: "22px" }}></img>
                    </button>
                </div>*/}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "0px",
              opacity: "1",
              visibility: "visible",
            }}
          >
            <p
              style={{
                margin: "auto",
                marginLeft: "15px",
                marginRight: "5px",
                verticalAlign: "center",
                fontSize: "16px",
                fontWeight: 300,
              }}
            >
              Repeat:
            </p>
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.repeat}
                style={{
                  height: "30px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  backgroundColor: "white",
                  fontWeight: 300,
                }}
                onChange={(e: any) => {
                  props.setRepeat(e.target.value as number);
                  props.setRepeatMemo(e.target.value as number);
                }}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#bdd1b9",
                  },
                }}
              >
                {repeatOptions().map((v, i) => (
                  <MenuItem value={i} key={i}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ display: "flex", opacity: 1 }}>
              <p
                style={{
                  margin: "auto",
                  marginLeft: "20px",
                  marginRight: "5px",
                  verticalAlign: "center",
                  fontSize: "16px",
                  fontWeight: 300,
                }}
              >
                Repeat-gap:
              </p>
              <NumberInput
                input={props.gap}
                setInput={props.setGap}
                useHandle={false}
                acceptNegative={true}
                setDoIfClickedOutside={props.setDoIfClickedOutside}
              />
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default Tools;
