import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

type Props = {
  open: boolean;
  handleClose: () => void;
  customContainer?: any;
};

const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  handleClose,
  customContainer = undefined,
  children,
}) => {
  const ref = useRef(null);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        ref={ref}
        open={open}
        onClose={handleClose}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
        static
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/30 backdrop-blur-[3px]" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={
              customContainer ??
              "relative z-10 h-full max-h-screen w-full overflow-scroll border border-neutral-10 bg-white shadow-xl scrollbar-hide md:h-auto md:max-h-[90vh] md:w-auto md:rounded"
            }
          >
            {children}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;
