import { backendApiAddress } from "./backendApi";

export type UrlPath = `/${string}`;

export const apiVersion = "2023-03-30";

export async function fetchGetJSON(url: string) {
  try {
    const data = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit""
      headers: {
        Accept: `*/*;version=${apiVersion}`,
        //"Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      //redirect: "follow", // manual, *follow, error
      //referrerPolicy: "no-referrer", // no-referrer, *client
    }).then((res) => res.json());
    return data;
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function fetchPostJSON(url: UrlPath, data?: {}) {
  try {
    // Default options are marked with *
    const response = await fetch(`${backendApiAddress}${url}`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit""
      headers: {
        Accept: `*/*;version=${apiVersion}`,
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
  } catch (err: any) {
    console.log("err", err);

    throw new Error(err.message);
  }
}

export async function fetchPostEmptyResponse(url: UrlPath, data?: {}) {
  try {
    // Default options are marked with *
    return await fetch(`${backendApiAddress}${url}`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit""
      headers: {
        Accept: `*/*;version=${apiVersion}`,
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
    });
  } catch (err: any) {
    console.log("err", err);

    throw new Error(err.message);
  }
}

export async function fetchPutJSON(url: UrlPath, data?: {}) {
  try {
    const response = await fetch(`${backendApiAddress}${url}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        Accept: `*/*;version=${apiVersion}`,
      },
    });
    return await response.json(); // parses JSON response into native JavaScript objects
  } catch (err: any) {
    throw new Error(err.message);
  }
}
export async function fetchDeleteJSON(url: UrlPath, data?: {}) {
  try {
    // Default options are marked with *
    const response = await fetch(`${backendApiAddress}${url}`, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit""
      headers: {
        Accept: `*/*;version=${apiVersion}`,
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
    });
    return await response.ok; // parses JSON response into native JavaScript objects
  } catch (err: any) {
    throw new Error(err.message);
  }
}
