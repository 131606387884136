import { useEffect, useRef } from "react";
import { Util } from "../static/util";
function PatternCanvas(props: any) {
  const canvasRef = useRef<any>(null);

  function height() {
    return numSize(props.pattern.sizeY);
  }

  function width() {
    return numSize(props.pattern.sizeX);
  }

  function numSize(num: number) {
    return numMod() * num;
  }

  function numMod() {
    const height = props.pattern.sizeY;
    const width = props.pattern.sizeX;
    const max = Math.max(height, width);
    return Math.floor(36 / max);
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas!!.getContext("2d");
    context.clearRect(0, 0, width(), height());
    const grid = props.pattern.grid;
    const _numMod = numMod();
    const patternIsMonocolored = Util.patternIsMonocolored(props.pattern);
    for (let y = 0; y < grid.length; y++) {
      for (let x = 0; x < grid[0].length; x++) {
        if (grid[y][x] !== -1) {
          //Our first draw
          context.fillStyle = patternIsMonocolored
            ? "#000000"
            : props.colors[grid[y][x]];
          context.fillRect(x * _numMod, y * _numMod, _numMod, _numMod);
        }
      }
    }
  }, [props.pattern, props.colors]);

  return (
    <canvas
      ref={canvasRef}
      width={width() + ""}
      height={height() + ""}
      style={{ height: height() + "px", width: width() + "px" }}
    />
  );
}

export default PatternCanvas;
