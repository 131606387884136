import React from "react";
import { Settings } from "../static/settings";
import { Tooltip } from "@mui/material";
function ColorsPopup(props: any) {
  return (
    <div id="showColorsPopup" style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          width: "0",
          height: "0",
          marginLeft: "15px", //UGLY: Because border pushes it. Bad fix.
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderBottom: "5px solid rgba(0,0,0,0.5)",
          marginTop: "45px",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          width: "0",
          height: "0",
          marginLeft: "15px", //UGLY: Because border pushes it. Bad fix.
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderBottom: "5px solid #f9f5f2",
          marginTop: "46px",
          zIndex: 4,
        }}
      ></div>
      <div
        className="box"
        style={{
          border: "0.5px solid rgba(0,0,0,0.5)",
          position: "absolute",
          padding: "10px",
          marginTop: "50px",
          marginLeft: -(97.5 - 5) + "px", //UGLY: 6.5 most likely from border
          width: "225px",
          backgroundColor: "#f9f5f2",
          zIndex: 2,
          borderRadius: "5px",
        }}
      >
        <div
          id="colorsAllContainer"
          style={{
            display: "flex",
            flexWrap: "wrap",
            overflow: "scroll",
            overflowX: "hidden",
            overflowY: "hidden",
            height: Math.ceil(Settings.colorsAll.length / 5) * 40 + "px",
            transition: "height 1s",
            justifyContent: "center",
          }}
        >
          {Settings.colorsAll.map((color: string, index: number) => (
            <Tooltip
              title={"Set: " + Settings.colorsAllNames[index]}
              key={index}
              disableInteractive
            >
              <button
                className="gridButton small"
                style={{
                  margin: "0px",
                  borderRadius: "0px",
                  opacity: props.colors[props.colorID] === color ? 0.25 : 1,
                }}
                onClick={() => {
                  const colors = [...props.colors];
                  colors[props.colorID] = color;
                  props.setColors(colors);
                }}
              >
                <div
                  style={{
                    backgroundColor: color,
                    minWidth: "20px",
                    maxWidth: "20px",
                    height: "20px",
                    border: "0.5px solid black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                ></div>
              </button>
            </Tooltip>
          ))}
        </div>
        <div
          className="buttonContainer"
          style={{
            marginTop: "5px",
          }}
        >
          <button
            className="gridButton small"
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              height: "35px",
              margin: "auto",
              display: "flex",
              gap: "5px",
            }}
            onClick={() => {
              props.setShowColorsPopup(false);
            }}
          >
            <p>Finish</p>
          </button>
        </div>
      </div>
      <div></div>{" "}
      {/*Ugly: Necessary or else it will be affected by buttonContainer:lastChild*/}
    </div>
  );
}

export default ColorsPopup;
