import { SweaterPart } from "../SweaterPart";
import { SweaterPartArea } from "../enums";
import { Util } from "../static/util";

export class Raglan {
    shirt = {
      "Left Arm": [
          1297.0263157894738,
          575,
          1835.9736842105262,
          1878
      ],
      "Right Arm": [
          2201.0263157894738,
          575,
          2739.9736842105262,
          1878
      ],
      "Collar": [
          1129.1842105263158,
          1905,
          2981.815789473684,
          1998
      ],
      "Back Torso": [
          1148.2894736842104,
          2255,
          1956.7105263157896,
          3389
      ],
      "Front Torso": [
          2112.2894736842104,
          2255,
          2920.7105263157896,
          3389
      ]
  } as const

    parts() {
      let leftArm = new SweaterPart(
        "Left Arm",
        SweaterPartArea.LeftArm,
        Util.makeGrid(256),
        ...(this.shirt["Left Arm"]),
        1065
      );
      let rightArm = new SweaterPart(
        "Right Arm",
        SweaterPartArea.RightArm,
        Util.makeGrid(256),
        ...(this.shirt["Right Arm"]),
        1065
      );
      let back = new SweaterPart(
        "Back Torso",
        SweaterPartArea.BackTorso,
        Util.makeGrid(256),
        ...(this.shirt["Back Torso"]),
        2750
      );
  
      let front = new SweaterPart(
        "Front Torso",
        SweaterPartArea.FrontTorso,
        Util.makeGrid(256),
        ...(this.shirt["Front Torso"]),
        2750
      );
  
      let collar = new SweaterPart(
        "Collar",
        SweaterPartArea.Collar,
        Util.makeGrid(256),
        ...(this.shirt["Collar"]),
        -1,
        true //is_inverted
      );

      return [leftArm, rightArm, front, back, collar]
    }
}