import React, { useState, useEffect } from "react";
import Alert, { AlertValues } from "./components/ui/Alert/Alert";
import { LoginUserCredentials, RegisterUserCredentials } from "./types/auth";
import LoginForm from "./components/forms/LoginForm";
import RegisterForm from "./components/forms/RegisterForm";
import Button from "./components/ui/Button/Button";
import mixpanel from "mixpanel-browser";
import { Cross1Icon } from "@radix-ui/react-icons";
import { methodData } from "../httpRequests";
import { backendApiAddress } from "./utils/backendApi";
import { Global } from "../static/global";

type Props = {
  handleClose?: () => void;
};

const AuthModalContent: React.FC<React.PropsWithChildren<Props>> = ({
  handleClose,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginStateIsLoading, setLoginStateIsLoading] = useState(false);
  const [registerStateIsLoading, setRegisterStateIsLoading] = useState(false);
  const login = (it: any) => {
    console.log("backendApiAddress:");
    console.log(backendApiAddress);
    const baseURL = `${backendApiAddress}/api`;
    return methodData(
      `${baseURL}/auth/login_with_password`,
      it,
      "POST",
      async (it: any) => {
        console.log("cookie:");
        console.log(it.headers["getSetCookie"]());
        return it;
      }
    );
  };
  const register = (it: any) => {
    const baseURL = `${backendApiAddress}/api`;

    return methodData(
      `${baseURL}/auth/register_with_password`,
      it,
      "POST",
      async (it: any) => {
        return it;
      }
    );
  };

  const [type, setType] = useState<"login" | "register">("login");

  useEffect(() => {
    if (isLoggedIn) {
      const timer = setTimeout(() => {
        setLoginAlert(null);
        setRegisterAlert(null);
        handleClose && handleClose();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isLoggedIn, handleClose]);

  const [loginAlert, setLoginAlert] = useState<AlertValues | null>(null);
  const handleLogin = (loginCredentials: LoginUserCredentials) => {
    setLoginStateIsLoading(true);
    login(loginCredentials)
      .then((it: any) => {
        if (it.ok) {
          mixpanel.track("login_success");
          setIsLoggedIn(true);
          setLoginAlert({ severity: "success", message: "Du ble logget inn!" });
        } else {
          mixpanel.track("login_error");
          setLoginAlert({
            severity: "error",
            message: "Epost eller passord er feil.",
          });
        }
        setLoginStateIsLoading(false);
      })
      .catch((it: any) => setLoginStateIsLoading(false));
  };

  const [registerAlert, setRegisterAlert] = useState<AlertValues | null>(null);
  const handleRegister = (registerCredentials: RegisterUserCredentials) => {
    setRegisterStateIsLoading(true);
    register(registerCredentials)
      .then((it: any) => {
        if (it.ok) {
          setIsLoggedIn(true);
          mixpanel.track("register_success");
          setRegisterAlert({
            severity: "success",
            message: "Bruker ble registrert!",
          });
        } else {
          mixpanel.track("register_error");
          setRegisterAlert({
            severity: "error",
            message: "Kunne ikke registrere bruker.",
          });
        }
        setRegisterStateIsLoading(false);
      })
      .catch((it: any) => setRegisterStateIsLoading(false));
  };
  return (
    <>
      {handleClose && (
        <div className="sticky top-0 flex justify-end">
          <button className="p-4" onClick={handleClose}>
            <Cross1Icon />
          </button>
        </div>
      )}
      <div className="mx-6 mt-12 mb-20 md:mx-28 md:w-80">
        {type === "login" && (
          <>
            <LoginForm onSubmit={handleLogin} loading={loginStateIsLoading} />
            {loginAlert && (
              <Alert severity={loginAlert.severity} className="mt-4">
                {loginAlert.message}
              </Alert>
            )}
            <div className="mt-10 flex flex-col items-center gap-y-3">
              <p className="text-par-s-reg text-dark-brown">
                Har du ikke en Woolit-konto?
              </p>
              <Button
                className="w-full"
                variant="secondary"
                onClick={() => setType("register")}
              >
                Registrer deg
              </Button>
            </div>
          </>
        )}
        {type === "register" && (
          <>
            <RegisterForm
              onSubmit={handleRegister}
              loading={registerStateIsLoading}
            />
            {registerAlert && (
              <Alert severity={registerAlert.severity} className="mt-4">
                {registerAlert.message}
              </Alert>
            )}
            <div className="mt-10 flex flex-col items-center gap-y-3">
              <p className="text-par-s-reg text-dark-brown">
                Har du allerede en Woolit-konto?
              </p>
              <Button
                className="w-full"
                variant="secondary"
                onClick={() => setType("login")}
              >
                Logg inn
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AuthModalContent;
