import { checkEmailUnique } from "../lib/checkEmailUnique";
import { object, string, boolean, InferType, ref as yupRef } from "yup";

export const RegisterUserSchema = object({
  first_name: string()
    .required("Du må oppgi fornavn")
    .min(2, "Fornavn må være minst to bokstaver"),
  last_name: string()
    .required("Du må oppgi etternavn")
    .min(2, "Etternavn må være minst to bokstaver"),
  email: string()
    .required("Du må oppgi epost")
    .email("Dette er ikke en gyldig epost")
    .test(
      "checkEmailUnique",
      "Denne eposten er allerede registrert",
      checkEmailUnique
    ),
  phone: string().required("Du må oppgi telefonnummer"),
  password: string()
    .required("Du må oppgi et passord")
    .min(8, "Passord må være minst 8 tegn"),
  confirmPassword: string()
    .required("Du må bekfrefte passordet")
    .oneOf([yupRef("password"), ""], "Passordfeltene må være like"),
  newsletter: boolean().notRequired(),
});

export type RegisterUserCredentials = InferType<typeof RegisterUserSchema>;

export const LoginUserSchema = object({
  email: string()
    .required("Du må oppgi epost")
    .email("Dette er ikke en gyldig epost"),
  password: string().required("Du må oppgi et passord"),
});

export type LoginUserCredentials = InferType<typeof LoginUserSchema>;

export const EditPersonalInformationSchema = object({
  first_name: string().nullable(),
  middle_name: string().nullable(),
  last_name: string().nullable(),
  phone: string().nullable(),
  address: string().nullable(),
  postnumber: string().nullable(),
  postplace: string().nullable(),
  knitter_application_about: string().nullable(),
});

export type EditablePersonalInformation = InferType<
  typeof EditPersonalInformationSchema
>;

export type User = {
  id: number;
  firstName?: String;
  lastName?: String;
  middleName?: String;
  email: string;
  isAdmin: boolean;
  subscriber: boolean;
  modules: string[];
};

export type UserDetailed = {
  id: number;
  image_url?: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  phone?: string;
  email?: string;
  facebook_email?: string;
  address?: string;
  postnumber?: string;
  postplace?: string;
  knitterApplicationAbout?: string;
  knitter_state?: string;
  is_admin: boolean;
  is_knitter: boolean;
  intercom_hash: string;
  notification_channels: NotificationChannels;
  invoice_customer: boolean;
};

export type NotificationChannels = { email: boolean; sms: boolean };
