import { SVGProps } from "react";

const FacebookIconRound = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12.07C24 5.405 18.627 0 12 0S0 5.404 0 12.07c0 6.026 4.388 11.02 10.125 11.925V15.56H7.078v-3.49h3.047V9.412c0-3.025 1.791-4.696 4.533-4.696 1.313 0 2.686.236 2.686.236v2.97H15.83c-1.491 0-1.956.93-1.956 1.885v2.265h3.328l-.532 3.489h-2.796v8.435C19.612 23.089 24 18.095 24 12.07Z"
      fill="#ffffff"
    />
  </svg>
);

export default FacebookIconRound;
