import React from "react";

import { useState } from "react";
import { getScreenshot, getSweaterParts } from "./scene";
import { Settings } from "../static/settings";
import { methodData, post } from "../httpRequests";
import { Util } from "../static/util";
import Modal from "../login/Modal";
import Auth from "../login/Auth";
import styled from "@emotion/styled";
import GridButton from "../components/GridButton";
import { backendApiAddress } from "../login/utils/backendApi";
import CloudSaves from "./CloudSaves";
import Button from "../login/components/ui/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectknitability,
  setKnitability,
  setWarningOverlay,
} from "../../store/knitabilityslice";
import { selectKey } from "../../store/keyslice";
import { KnitabilityProblem } from "../enums";
import { useAppDispatch } from "../../store/hooks";

function DesignStudioBanner() {
  const [show, setShow] = useState(true);

  if (!show) {
    return <></>;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "25px",
        right: "25px",
        maxWidth: "calc(50% - 25px - 25px)",
        backgroundColor: "white",
      }}
    >
      <div style={{ display: "flex", gap: "1.25rem", padding: "15px" }}>
        <img
          alt=""
          src={"design_studio.png"}
          style={{
            objectFit: "cover",
            height: "60px",
            margin: "auto",
          }}
        ></img>
        <p
          style={{
            lineHeight: "1.5rem",
            fontWeight: 400,
          }}
        >
          <span
            style={{
              fontWeight: 500,
            }}
          >
            Enjoy making your own knit patterns?
          </span>{" "}
          Get a free intro to{" "}
          <a
            style={{
              color: Settings.emphasisColor,
              textDecoration: "none",
            }}
            href="https://design.woolit.no"
          >
            Woolit Design Studio
          </a>{" "}
          so you can earn from your own designs!
        </p>
        <button
          className="gridButton small"
          style={{
            margin: "auto",
            marginLeft: "10px",
            marginRight: "10px",
            border: "none",
            fontSize: "16px",
            fontWeight: 100,
          }}
          onClick={() => {
            setShow(false);
          }}
        >
          <img
            src="ui/close.png"
            style={{
              height: "15px",
              minWidth: "15px",
              maxWidth: "15px",
            }}
          ></img>
        </button>
      </div>
    </div>
  );
}

function KnittingPreview(props: any) {
  const [showLogin, setShowLogin] = useState(false);
  const [showCloudSaves, setShowCloudSaves] = useState(false);

  // async function putData(
  //   url = "",
  //   data = {},
  //   retrieveMethod = (it: any) => it
  // ) {
  //   return methodData(url, data, "PUT", retrieveMethod);
  // }
  async function postData(
    url = "",
    data = {},
    retrieveMethod = (it: any) => it
  ) {
    return methodData(url, data, "POST", retrieveMethod);
  }

  // Unused, but could be useful post competition
  // function displayYarn() {
  //   for (let n = 0; n < 5; n++) {
  //     const colorSceneIndex = n;
  //     const colorScene = colorsScene[colorSceneIndex];
  //     const colorIndex = Settings.colorsAll.indexOf(colorScene);
  //     const colorID: string = Settings.colorsAllID[colorIndex];
  //     const colorName: string = Settings.colorsAllNames[colorIndex];
  //     console.log(`${n + 1}. color is ${colorID}:${colorName}`);
  //   }
  // }

  const _key = useSelector(selectKey);

  function key() {
    const pathName = window.location.pathname.substring(1);
    return pathName == _key ? undefined : _key; // Make a new key if you loaded by pathName
  }

  const knitabilityUIArgs = useSelector(selectknitability);

  function knitabilityProblemToMessage(knitabilityProblem: KnitabilityProblem) {
    let status = "";
    let color = "";
    switch (knitabilityProblem) {
      case KnitabilityProblem.None: {
        status = "Good knitability";
        color = "rgba(93,149,21,255)";
        break;
      }
      case KnitabilityProblem.Warning: {
        status = "Ok knitability";
        color = Settings.knitabilityWarningColorDisplay;
        break;
      }
      case KnitabilityProblem.Error: {
        status = "Poor knitability";
        color = Settings.knitabilityErrorColor;
        break;
      }
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          margin: "0px -5px 0px 5px",
        }}
      >
        <p
          style={{ color: color, fontWeight: "400", margin: "0px 0px 0px 5px" }}
        >
          {status}
        </p>
      </div>
    );
  }

  const dispatch = useAppDispatch();
  const knitability = useSelector(selectknitability);

  const knitabilityUI = () => (
    <div
      style={{
        display: "flex",
        alignItems: "space-between",
        gap: "10px",
      }}
    >
      {knitabilityProblemToMessage(knitability.isKnitable)}
      <div className="buttonContainer">
        <GridButton
          title="Knitability Status"
          onClick={() => {
            dispatch(setWarningOverlay(!knitability.warningOverlay));
          }}
          imageURL={"ui/eye_icon.png"}
          isSelected={knitability.warningOverlay}
          small={true}
          size={20}
          customStyle={knitability.warningOverlay ? {} : { border: "none" }}
        />
      </div>
    </div>
  );

  const finishButton = (
    <div
      className="flex justify-spacebetween items-end"
      style={{
        position: "absolute",
        height: "100%",
        width: "50vw",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          borderRadius: "37px",
          margin: "auto",
          marginBottom: "30px",
          padding: "10px",
          gap: "10px",
        }}
      >
        {knitabilityUI()}
        <Button
          style={{
            pointerEvents: "auto",
            borderRadius: "37px",
            fontSize: 20,
            fontWeight: 500,
            margin: "auto",
            marginLeft: "50px",
            width: "40px",
            height: "40px",
            backgroundColor: "#e0dddb",
          }}
          variant="tertiary"
          loading={props.saving}
          onClick={() => {
            post(props.setSaving, key(), false);
          }}
          noPadding
        >
          <img
            src="ui/save.png"
            style={{ height: "25px", width: "25px" }}
          ></img>
        </Button>
        <Button
          style={{
            pointerEvents: "auto",
            marginLeft: "auto",
            borderRadius: "37px",
            fontSize: 18,
            fontWeight: 500,
            width: "160px",
            height: "40px",
          }}
          variant="quaternary"
          loading={props.saving}
          onClick={() => {
            post(props.setSaving, key(), true);
          }}
        >
          Knit this!
        </Button>
      </div>
    </div>
  );

  function checkIsLoggedIn() {
    const baseURL = `${backendApiAddress}/api`;
    methodData(`${baseURL}/me`, {}, "GET", async (it: any) => {
      console.log(await it.json());
      return it;
    }).then((it: any) => {
      if (it.ok) {
        setShowCloudSaves(true);
      } else {
        setShowLogin(true);
      }
    });
  }

  function cloudOnClick() {
    checkIsLoggedIn();
  }

  const cloudUpload = (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        width: "50vw",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        pointerEvents: "none",
        padding: "20px",
      }}
    >
      <GridButton
        onClick={() => {
          cloudOnClick();
        }}
        imageURL={"ui/cloud.png"}
      />
    </div>
  );

  return (
    <div>
      {/*{cloudUpload}*/}
      <DesignStudioBanner />
      {finishButton}
      <div
        id="canvas"
        style={{
          height: "94vh", //95vh adds scrollbars, therefore subtract 1vh
          width: "50vw",
        }}
      ></div>
    </div>
  );
}

export default KnittingPreview;
