import { Tooltip } from "@mui/material";
import React from "react";

function SizePopup(props: any) {
  return (
    <div id="showSizePopup" style={{ position: "relative" }}>
      <div
        className="box"
        style={{
          border: "0.5px solid rgba(0,0,0,0.5)",
          position: "absolute",
          padding: "2px",
          marginTop: "0px",
          marginLeft: props.marginLeft,
          backgroundColor: "#f9f5f2",
          borderRadius: "3px",
          zIndex: 1,
        }}
      >
        {/*<p style={{ fontSize: "10px", margin: "auto", marginTop: "-5px", marginBottom: "2px", textAlign: "center" }}>Size:</p>*/}
        <div className="buttonContainer">
          {props.sizes.map((size: number, index: number) => (
            <Tooltip title={"Size: " + size} key={size}>
              <button
                className="gridButton small"
                style={{
                  margin: "auto",
                  marginLeft: "0",
                  marginRight: "0",
                  ...(size === 1
                    ? {
                        borderColor: "rgba(127,161,117,1)",
                        borderWidth: "3px",
                      }
                    : {}),
                }}
                onClick={() => {
                  //Update doIfClickedOutside as well
                  props.setDrawType(props.drawType, size);
                  props.setShowSizePopup(false);
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#000000",
                    width: 15 + index * 3 + "px",
                    height: 15 + index * 3 + "px",
                    border: "0.5px solid black",
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                  }}
                ></div>
                <p
                  style={{
                    margin: "auto",
                    color: "white",
                    zIndex: 1,
                    fontSize: "10px",
                  }}
                >
                  {size}
                </p>
              </button>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SizePopup;
