import React from "react";
import SweaterDesigner from "./sweaterdesigner/SweaterDesigner";
import mixpanel from "mixpanel-browser";
import { mixpanelToken } from "./config";

function App() {
  mixpanel.init(mixpanelToken, { ignore_dnt: true, disable_persistence: true });

  return <SweaterDesigner />;
}

export default App;
