import { fetchPostEmptyResponse } from "../utils/api-helpers";

export const checkEmailUnique = async (
  email: string | undefined
): Promise<boolean> => {
  const res = await fetchPostEmptyResponse("/api/auth/check_email", {
    email: email,
  });
  if (res.ok) {
    return true;
  }
  return false;
};
