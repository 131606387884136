import Button from "../login/components/ui/Button/Button";
import { Util } from "../static/util";

import { ReactComponent as ProfileIcon } from "./profile_icon.svg";

function Profile(props: any) {
  return (
    <>
      <p
        style={{
          fontWeight: "500",
        }}
      >
        {Util.titleCase(props.name)}
      </p>
      <Button
        style={{
          display: "flex",
          minHeight: "30px",
          maxHeight: "30px",
          minWidth: "30px",
          maxWidth: "30px",
          backgroundColor: "#d5ce8a",
          borderRadius: "50%",
        }}
        onClick={() => {
          props.onClick();
        }}
        loading={props.saving}
        variant="tertiary"
        noPadding
      >
        <ProfileIcon
          style={{
            margin: "auto",
            height: "20px",
          }}
        ></ProfileIcon>
      </Button>
    </>
  );
}
export default Profile;
