export enum RepeatMode {
  NONE,
  ONE,
  BOTH,
  ALL,
}

export enum SweaterPartArea {
  None, //Unnecessary if this wasnt javascript, where 0 => undefined
  LeftArm,
  RightArm,
  FrontTorso,
  BackTorso,
  Collar,
}
export enum SweaterPartAreaGroup {
  None, //Unnecessary if this wasnt javascript, where 0 => undefined
  Arms,
  Torso,
  Collar,
}
export enum DrawTypes {
  None,
  Brush,
  Bucket,
  Pattern,
  Eraser,
  Move,
  Warning,
}

export enum KnitabilityProblem {
  None,
  Warning,
  Error,
}
