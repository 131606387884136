import { useEffect, useRef, useState } from "react";
import { Util } from "../static/util";
import { Tooltip } from "@mui/material";

function NumberInput(props: any) {
  const input = useRef(null);
  function parseClamp(numberText: string) {
    let number = parseInt(numberText);
    if (numberText === "" || isNaN(number)) {
      number = 0;
    }
    return Util.clamp(number, props.acceptNegative ? -9 : 1, 36);
  }
  function blur() {
    (input!.current! as HTMLInputElement).blur();
  }
  const [inputRaw, setInputRaw] = useState<string | number>(props.input || 0);
  const [manualChange, setManualChange] = useState<boolean>(false);

  useEffect(() => {
    if (!manualChange) {
      setInputRaw(props.input);
    }
    setManualChange(false);
  }, [props.input]);
  return (
    <Tooltip title={props.title}>
      <input
        type={props.useHandle ? "number" : "text"}
        ref={input}
        value={inputRaw}
        style={{
          outlineColor: "" + inputRaw !== "" + props.input ? "red" : "#bdd1b9",
          ...(props.customStyle || {
            height: "35px",
            margin: "auto",
            width: "30px",
            textAlign: "center",
            marginRight: "5px",
            fontWeight: 300,
          }),
        }}
        onChange={(e) => {
          const text = e.target.value.replace(/[^0-9/-]/g, ``);
          setInputRaw(text);
          props.setInput(parseClamp(text));
          if (props.input !== parseClamp(text)) {
            setManualChange(true);
          }

          props.setDoIfClickedOutside([
            () => {
              blur();
            },
            () => input.current,
          ]);
        }}
        onBlur={() => {
          setInputRaw(parseClamp(inputRaw + ""));
        }}
        onKeyDown={(e) => {
          if (["e", "E", "+"].includes(e.key)) {
            e.preventDefault();
            return;
          }
          if (e.key === "Enter") {
            blur();
          }
        }}
      ></input>
    </Tooltip>
  );
}

export default NumberInput;
