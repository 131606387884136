import { useEffect, useRef, useState } from "react";
import {
  DrawTypes,
  KnitabilityProblem,
  RepeatMode,
  SweaterPartAreaGroup,
} from "../enums";
import {
  getSweaterParts,
  getSweaterPartsExceptCollar,
  runAfterLoadCanvasPush,
  runAfterLoadSweaterPush,
  setUpdateCanvasNextFrame,
} from "../knittingpreview/scene";
import { Pattern } from "../Pattern";
import { Settings } from "../static/settings";
import { SweaterPart } from "../SweaterPart";
import {
  loadGrid,
  drawSelection,
  drawGrid,
  clearPreview,
  hasLoadedImages,
  prevSetupDrawable,
  drawSelectionProps,
} from "./gridcanvas";
import Drawable from "../Drawable";
import { Util } from "../static/util";
import GridButton from "../components/GridButton";
import NumberInput from "../components/NumberInput";
import GridCell from "./GridCell";
import { Tooltip } from "@mui/material";
import { Global } from "../static/global";
import { isKnitable } from "../KnitableChecker";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import GridOverlay from "./GridOverlay";
import React from "react";
import { useAppDispatch } from "../../store/hooks";
import {
  selectknitability,
  setKnitability,
} from "../../store/knitabilityslice";
import { useSelector } from "react-redux";

let scrollInfo: number[] = [];

function Grid(props: any) {
  let lastPos: number[] = [];
  function setLastPos(value: any) {
    lastPos = value;
  }

  let moveStartPos: number[] = [];

  const [gridSize, setGridSize] = useState<number>(Global.gridSize);

  const _gridHTML = useRef(null);
  const _gridContainer = useRef(null);

  const [grid, setGrid] = useState(
    Util.make2DArray(Global.gridSizeX, Global.gridSizeY)
  );

  const [stateIsEmpty, setStateIsEmpty] = useState<boolean>(true);

  // UndoStack contains prev and current state
  const [undoStack, setUndoStack] = useState<any[]>([]);
  // RedoStack contains next state
  const [redoStack, setRedoStack] = useState<any[]>([]);

  const [emptyStateJSON, setEmptyStateJSON] = useState<any>(undefined);

  const [patternWidth, setPatternWidth] = useState<number>(
    props.selectedEditPattern?.sizeX ?? -1
  );
  const [patternHeight, setPatternHeight] = useState<number>(
    props.selectedEditPattern?.sizeY ?? -1
  );

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [snap, setSnap] = useState<boolean>(false);

  const _warningOverlay = useSelector(selectknitability).warningOverlay;
  const warningOverlay = props.isSweaterPart ? _warningOverlay : false;

  const handleClose = () => {
    setAlertOpen(false);
  };

  function getGridHTML() {
    return _gridHTML.current! as HTMLDivElement;
  }

  function getGridContainer() {
    return _gridContainer.current! as HTMLDivElement;
  }

  function getScrollInfo() {
    const gridContainer = getGridContainer();
    const clientWidth = gridContainer.clientWidth;
    const clientHeight = gridContainer.clientHeight;
    const scrollTop = gridContainer.scrollTop;
    const scrollHeight = gridContainer.scrollHeight;
    const scrollLeft = gridContainer.scrollLeft;
    const scrollWidth = gridContainer.scrollWidth;
    return [
      clientWidth,
      clientHeight,
      scrollTop,
      scrollHeight,
      scrollLeft,
      scrollWidth,
    ];
  }

  function zoom(direction: number) {
    scrollInfo = getScrollInfo();
    let amount = Math.ceil(gridSize / 5) - 1;
    if (gridSize < 10) {
      amount = 1;
    }
    const newGridSize = gridSize + amount * direction;
    setGridSize(Util.clamp(newGridSize, 2, 100));
  }

  function onKeyDown(event: any) {
    if (prevSetupDrawable !== props.drawable) return;
    if (event.ctrlKey || event.metaKey) {
      if (!event.shiftKey && event.key.toLowerCase() === "z") {
        event.preventDefault();
        if (undoStack.length > 1) performUndo();
      }
      if (
        event.key.toLowerCase() === "y" ||
        (event.shiftKey && event.key.toLowerCase() === "z")
      ) {
        event.preventDefault();
        if (redoStack.length > 0) performRedo();
      }
    }
  }

  //Incase they left the grid
  function onMouseUp(event: any) {
    updateUndo();
  }

  // Create the ref,
  const onKeyDownRef = useRef(onKeyDown);
  // and then update it on each re-render.
  onKeyDownRef.current = onKeyDown;

  // Create the ref,
  const onMouseUpRef = useRef(onMouseUp);
  // and then update it on each re-render.
  onMouseUpRef.current = onMouseUp;

  function loadStateFromLocalStorage() {
    const stateJSON = localStorage.getItem(Global.getSweaterStorageName());
    if (!stateJSON) {
      return;
    }

    const state = JSON.parse(stateJSON);
    setStateAndSaveIt(state, undoStack, redoStack);
  }

  function getState() {
    if (props.isSweaterPart) {
      return getSweaterParts().map((part: SweaterPart) => part.copyGrid());
    } else {
      return props.drawable.copyGrid();
    }
  }

  function undoButtonClick() {
    if (undoButtonState()) {
      performUndo();
    }
  }

  function redoButtonClick() {
    if (redoButtonState()) {
      performRedo();
    }
  }

  function trashButtonClick() {
    if (trashButtonState()) {
      setAlertOpen(true);
    }
  }

  function SaveButtonState() {
    return !Util.patternIsEmpty(props.selectedEditPattern.grid);
  }

  function SaveButtonClick(forceState: boolean = false) {
    if (SaveButtonState() || forceState) {
      props.SavePattern();
      props.setSelectedEditPattern(undefined);
    }
  }

  function undoButtonState() {
    return undoStack.length > 1;
  }

  function redoButtonState() {
    return redoStack.length > 0;
  }

  function trashButtonState() {
    return (props.drawable && !stateIsEmpty) || !props.isSweaterPart;
  }

  function initState() {
    //^ Semi-bug: Will not be empty if [] is ran by hot compile refresh
    if (props.isSweaterPart) {
      const modelFromPath = props.modelFromPath;
      if (modelFromPath === undefined) {
        loadStateFromLocalStorage();
      } else {
        setStateAndSaveIt(modelFromPath, undoStack, redoStack);
      }
    }
    updateUndo(); //Add the current state to init the undoStack
  }

  function init() {
    document.addEventListener("keydown", (e) => onKeyDownRef.current(e));
    document.addEventListener("mouseup", (e) => onMouseUpRef.current(e));
    // Any sweater part will do. Just necessary to initialize
    // imageData.data for "knit it" or saving.
    loadGrid(getSweaterParts()[0], (it: any) => {});
    runAfterLoadCanvasPush(() => {
      if (props.isSweaterPart) {
        setEmptyStateJSON(JSON.stringify(getState()));
      } else {
        const newEmptyStateJSON = JSON.stringify(
          Util.emptyPattern().copyGrid()
        );
        setEmptyStateJSON(newEmptyStateJSON);
        const stateJSON = JSON.stringify(getState());
        setStateIsEmpty(newEmptyStateJSON === stateJSON);
      }
    }, true);
    runAfterLoadSweaterPush(() => {
      if (props.isSweaterPart) {
        props.setSweaterHasLoaded(true);
      }
    });
  }

  useEffect(() => {
    if (!props.loadModel) return;
    init();
    runAfterLoadCanvasPush(() => {
      initState();
    }, false);
  }, [props.loadModel, props.modelFromPath]);

  useEffect(() => {
    if (!props.drawable) {
      setGrid([]);
      return;
    }
    updateGrid();
  }, [
    props.drawable,
    props.selectedEditPatternDone,
    undoStack,
    warningOverlay,
  ]);

  useEffect(() => {
    if (!props.drawable) return;
    checkKnitable();
  }, [grid]);

  useEffect(() => {
    if (!props.isSweaterPart) {
      props.setPatternIsUnsaved(undoStack.length > 1);
    }
  }, [undoStack]);

  // Used to fix the grid scroll after zooming
  useEffect(() => {
    if (scrollInfo.length === 0) return;
    const [
      clientWidth,
      clientHeight,
      scrollTop,
      scrollHeight,
      scrollLeft,
      scrollWidth,
    ] = scrollInfo;
    const [
      clientWidthNew,
      clientHeightNew,
      scrollTopNew,
      scrollHeightNew,
      scrollLeftNew,
      scrollWidthNew,
    ] = getScrollInfo();
    const dx =
      ((scrollLeft + clientWidth / 2) * scrollWidthNew) / scrollWidth -
      (scrollLeft + clientWidth / 2);
    const dy =
      ((scrollTop + clientHeight / 2) * scrollHeightNew) / scrollHeight -
      (scrollTop + clientHeight / 2);
    getGridContainer().scrollBy(dx, dy);
  }, [gridSize]);

  // Used for transferring undo/redo/trash to another component
  useEffect(() => {
    if (props.sendUndoRedoTrashRef) return;
    props.undoButtonClickRef.current = undoButtonClick;
    props.redoButtonClickRef.current = redoButtonClick;
    props.trashButtonClickRef.current = trashButtonClick;
    props.setUndoButtonState(undoButtonState());
    props.setRedoButtonState(redoButtonState());
    props.setTrashButtonState(trashButtonState());
  }, [grid, undoStack]);

  // Used for resizing pattern
  useEffect(() => {
    if (props.isSweaterPart) return;
    const selectedEditPattern = props.drawable as Pattern;
    selectedEditPattern.sizeX = patternWidth;
    selectedEditPattern.sizeY = patternHeight;
    selectedEditPattern.updateDxDy();
    updateGrid();
  }, [patternHeight, patternWidth]);

  function setStateAndSaveIt(
    state: any,
    undoStack: any,
    redoStack: any,
    onlySelected: boolean = false
  ) {
    if (props.isSweaterPart) {
      const sweaterParts = getSweaterParts();
      for (let n = 0; n < getSweaterParts().length; n++) {
        if (
          onlySelected &&
          sweaterParts[n].areaGroup() !== props.drawable.areaGroup()
        )
          continue;
        sweaterParts[n].grid = state[n];
        sweaterParts[n].grid = sweaterParts[n].copyGrid(); // Lose reference
        sweaterParts[n].setDirty(); //NB
      }
      setUpdateCanvasNextFrame();
      saveStateToLocalStorage(state, undoStack, redoStack);
    } else {
      props.drawable.grid = state;
      props.drawable.grid = props.drawable.copyGrid();
      const stateJSON = JSON.stringify(state);
      setStateIsEmpty(emptyStateJSON === stateJSON);
    }
    if (props.drawable) drawGrid(props.drawable); //NB
  }

  function saveStateToLocalStorage(state: any, undoStack: any, redoStack: any) {
    const stateJSON = JSON.stringify(state);
    localStorage.setItem(
      Global.getSizeIndexStorageName(),
      `${Global._sizeIndex}`
    );
    localStorage.setItem(
      Global.getKnittingMethodIndexStorageName(),
      `${Global._shirtTypeIndex}`
    );
    localStorage.setItem(Global.getSweaterStorageName(), stateJSON);
    setStateIsEmpty(emptyStateJSON === stateJSON);
  }

  function resetState(onlySelected: boolean) {
    const emptyState = JSON.parse(emptyStateJSON);
    setStateAndSaveIt(emptyState, undoStack, redoStack, onlySelected); // Unnecessary save but whatever
    updateUndo();
  }

  function updateUndo() {
    const state = getState();
    const stateJSON = JSON.stringify(state);

    const [prevState, colors] =
      undoStack.length > 0 ? undoStack[undoStack.length - 1] : [null, null];
    const prevStateJSON = JSON.stringify(prevState);
    if (
      stateJSON === prevStateJSON &&
      JSON.stringify(props.colors) === JSON.stringify(colors)
    ) {
      return;
    }
    const newUndoStack = [...undoStack, [state, props.colors]];
    setUndoStack(newUndoStack);
    setRedoStack([]);

    if (props.isSweaterPart) {
      saveStateToLocalStorage(state, newUndoStack, []);
    } else {
      if (emptyStateJSON !== undefined) {
        const stateJSON = JSON.stringify(state);
        setStateIsEmpty(emptyStateJSON === stateJSON);
      }
    }
  }

  function performUndo() {
    const newUndoStack = [...undoStack];
    const currentState = newUndoStack.pop();
    setUndoStack(newUndoStack);
    const newRedoStack = [...redoStack, currentState];
    setRedoStack(newRedoStack);

    const [prevState, colors] = newUndoStack[newUndoStack.length - 1];
    setStateAndSaveIt(prevState, newUndoStack, newRedoStack);
    props.setColors(colors);
  }

  function performRedo() {
    const newRedoStack = [...redoStack];
    const [newState, colors] = newRedoStack.pop();
    setRedoStack(newRedoStack);
    const newUndoStack = [...undoStack, [newState, colors]];
    setUndoStack(newUndoStack);

    setStateAndSaveIt(newState, newUndoStack, newRedoStack);
    props.setColors(colors);
  }

  function onMouseOver(
    endX: any,
    endY: any,
    mouseDown: boolean,
    mouseUp: boolean,
    preview: boolean
  ) {
    if (!hasLoadedImages) return;
    if (!props.drawable) return;
    if (!preview && lastPos.length === 0) return;
    if (prevSetupDrawable !== props.drawable) {
      updateGrid();
      return;
    }
    draw(endX, endY, mouseDown, mouseUp, preview);
  }

  function updateGrid() {
    loadGrid(props.drawable, (it: any) => {
      setGrid(it);
    });
    if (!props.isSweaterPart && props.getDrawType() === DrawTypes.Move) {
      props.setDrawType(DrawTypes.Brush, 1);
    }
  }

  function drawablesFromRepeatMode(preview: boolean) {
    let drawables: Drawable[] = [];
    let repeatMode = RepeatMode.NONE;
    let repeat = props.isSweaterPart ? props.repeat : 0;
    if (preview) {
      repeat = Math.min(repeat, 1);
    }
    repeatMode = props.repeatModes[repeat];
    switch (repeatMode) {
      case RepeatMode.NONE:
      case RepeatMode.ONE:
        drawables = [props.drawable];
        break;
      case RepeatMode.BOTH:
        drawables = getSweaterParts(props.drawable.areaGroup());
        break;
      case RepeatMode.ALL:
        drawables = Util.sortPriority(
          getSweaterPartsExceptCollar(),
          props.drawable
        );
        break;
    }
    return [drawables, repeat];
  }

  function draw(
    endX: any,
    endY: any,
    mouseDown: boolean,
    mouseUp: boolean,
    preview: boolean
  ) {
    const gridHTML = getGridHTML();
    const drawType = props.getDrawType();
    switch (drawType) {
      case DrawTypes.Pattern:
      case DrawTypes.Eraser:
      case DrawTypes.Brush: {
        let pattern = undefined;
        let gap = props.gap;
        if (drawType == DrawTypes.Brush) {
          pattern = loadBrush(props.brushID);
        } else if (drawType == DrawTypes.Eraser) {
          const eraserColor = props.isSweaterPart ? 0 : -1;
          pattern = loadBrush(props.brushID, eraserColor);
          pattern.eraser = true;
        } else {
          pattern = props.loadPattern(props.patternID);
        }
        if (drawType != DrawTypes.Pattern) {
          if (gap > 0) {
            gap -= pattern.grid[0].length;
            gap = Math.max(gap, 0);
          }
        }
        let [drawables, repeat] = drawablesFromRepeatMode(preview);
        const selectionProps = {
          drawables: drawables,
          pattern: pattern,
          x: endX,
          y: endY,
          gridHTML: gridHTML,
          drawType: DrawTypes.Brush,
          drawableInGrid: props.drawable,
          mirror: props.isSweaterPart ? props.mirror : 0,
          preview: preview,
          warningOverlay: warningOverlay,
          gap: gap,
          repeat: repeat > 0,
        };
        drawInterpolate(pattern, endX, endY, preview, selectionProps);
        break;
      }
      case DrawTypes.Bucket: {
        if (mouseDown) return; // Only perform at mouse up
        let [drawables, repeat] = drawablesFromRepeatMode(preview);
        const selectionProps = {
          drawables: drawables,
          pattern: new Pattern([[props.colorID]]),
          x: endX,
          y: endY,
          drawType: DrawTypes.Bucket,
          drawableInGrid: props.drawable,
          gridHTML: gridHTML,
          preview: preview,
          gap: Math.max(props.gap, 1),
          repeat: props.repeat > 0,
          mirror: props.mirror,
          warningOverlay: warningOverlay,
        };
        drawSelection(selectionProps);
        if (props.isSweaterPart && !preview)
          setUpdateCanvasNextFrame(props.drawable);
        break;
      }
      case DrawTypes.Move: {
        let [drawables, repeat] = drawablesFromRepeatMode(preview);
        if (!preview && moveStartPos.length === 0) {
          moveStartPos = [endX, endY];
        }
        const selectionProps = {
          drawables: drawables,
          pattern: new Pattern([[]]),
          x: endX,
          y: endY,
          drawType: DrawTypes.Move,
          drawableInGrid: props.drawable,
          gridHTML: gridHTML,
          preview: preview,
          gap: 0,
          repeat: props.repeat > 0,
          mirror: 0,
          warningOverlay: warningOverlay,
          moveInfo: {
            moveMode: props.moveMode,
            mouseUp: mouseUp,
            startPosY: moveStartPos[1],
          },
        };
        drawSelection(selectionProps);
        if (mouseUp) {
          moveStartPos = [];
        }
        if (props.isSweaterPart && !preview)
          setUpdateCanvasNextFrame(props.drawable);
        break;
      }
    }
  }

  function drawInterpolate(
    pattern: Pattern,
    endX: any,
    endY: any,
    preview: boolean,
    selectionProps: drawSelectionProps
  ) {
    let startX = lastPos[0];
    let startY = lastPos[1];
    if (preview) {
      startX = endX;
      startY = endY;
    }
    let numDraw =
      Math.max(Math.abs(endY - startY), Math.abs(endX - startX)) + 1;
    for (let n = 0; n < numDraw; n++) {
      let x =
        startX + Math.round(((endX - startX) * n) / Math.max(numDraw - 1, 1));
      let y =
        startY + Math.round(((endY - startY) * n) / Math.max(numDraw - 1, 1));
      lastPos = [x, y];
      selectionProps.x = x;
      selectionProps.y = y;
      const snap = drawSelection(selectionProps) ?? false;
      if (preview) setSnap(snap);
    }
    if (props.isSweaterPart && !preview)
      setUpdateCanvasNextFrame(props.drawable);
  }

  function loadBrush(brush: number, forceColor?: number) {
    let grid: number[][] = [];
    for (let y = 0; y < brush; y++) {
      grid.push([]);
      for (let x = 0; x < brush; x++) {
        grid[y].push(forceColor ?? props.colorID);
      }
    }
    const pattern = new Pattern(grid);
    pattern.brush = true;
    return pattern;
  }

  function drawPattern(
    pattern: Pattern,
    endX: number,
    endY: number,
    preview: boolean,
    drawables: Drawable[]
  ) {
    const gridHTML = getGridHTML();
    const selectionProps = {
      drawables: drawables,
      pattern: pattern,
      x: endX,
      y: endY,
      gridHTML: gridHTML,
      drawType: DrawTypes.Pattern,
      drawableInGrid: props.drawable,
      gap: props.gap,
      mirror: 0,
      preview: preview,
      warningOverlay: warningOverlay,
    };
    const snapped = drawSelection(selectionProps)!;
    setSnap(snapped);
    if (props.isSweaterPart && !preview)
      setUpdateCanvasNextFrame(drawables as SweaterPart[]);
  }

  function calculateBlackBorders(
    grid: any,
    x: number,
    y: number,
    isSweaterPart: boolean
  ) {
    const borders = [false, false, false, false]; //top right bottom left
    if (!props.drawable || grid[y][x] === -2) return borders;
    if (isSweaterPart) {
      const drawableGridIsEmpty = props.drawable.grid[0].length === 0;
      const drawableGridIsEven = props.drawable.grid[0].length % 2 === 0;
      if (!drawableGridIsEmpty && drawableGridIsEven) {
        if (x === grid[y].length / 2 - 1) {
          borders[1] = true;
        }
        if (x === grid[y].length / 2) {
          borders[3] = true;
        }
      }
    } else {
      if (y === 0 || grid[y - 1][x] === -2) {
        borders[0] = true;
      }
      if (x === grid[y].length - 1 || grid[y][x + 1] === -2) {
        borders[1] = true;
      }
      if (y === grid.length - 1 || grid[y + 1][x] === -2) {
        borders[2] = true;
      }
      if (x === 0 || grid[y][x - 1] === -2) {
        borders[3] = true;
      }
    }
    return borders;
  }

  const dispatch = useAppDispatch();

  function checkKnitable() {
    if (!props.isSweaterPart) return;
    const sweaterPartsArm = getSweaterParts(SweaterPartAreaGroup.Arms);
    const sweaterPartsTorso = getSweaterParts(SweaterPartAreaGroup.Torso);
    const sweaterPartsCollar = getSweaterParts(SweaterPartAreaGroup.Collar);
    const armInfos = sweaterPartsArm
      .map((sweaterPartArm) => isKnitable([sweaterPartArm]))
      .flat(1); // Seperate, since they are not connected to each other (like torso)
    const torsoInfos = isKnitable(sweaterPartsTorso);
    const collarInfos = isKnitable(sweaterPartsCollar);
    const infos = [...armInfos, ...torsoInfos, ...collarInfos];
    const infosShow = infos.filter((it) => it[0] === props.drawable);

    const warningGrids: any = {};
    let foundWarning = false;
    let foundError = false;
    for (let info of infosShow) {
      const [sweaterPart, x, y, problemDescription, color]: any = info;
      warningGrids[x + "," + y] = {
        description: problemDescription,
        color: color,
      };
      if (color === Settings.knitabilityWarningColor) {
        foundWarning = true;
      }
      if (color === Settings.knitabilityErrorColor) {
        foundError = true;
      }
    }
    Global._warningGrid = warningGrids;

    const knitabilityProblem = foundError
      ? KnitabilityProblem.Error
      : foundWarning
      ? KnitabilityProblem.Warning
      : KnitabilityProblem.None;

    dispatch(setKnitability(knitabilityProblem));
  }

  return (
    <div
      style={{
        ...(props.isSweaterPart
          ? { flex: "1" }
          : { height: props.editPatternHeight + "vh" }),
        display: "flex",
        position: "relative",
        flexDirection: "row",
        overflowY: "auto",
      }}
    >
      <GridOverlay
        undoStack={undoStack}
        patternHeight={patternHeight}
        drawable={props.drawable}
        setEditPatternHeight={props.setEditPatternHeight}
        setPatternHeight={setPatternHeight}
        SaveButtonClick={SaveButtonClick}
        SaveButtonState={SaveButtonState}
        patternWidth={patternWidth}
        setPatternWidth={setPatternWidth}
        undoButtonClick={undoButtonClick}
        undoButtonState={undoButtonState}
        redoButtonState={redoButtonState}
        redoButtonClick={redoButtonClick}
        trashButtonState={trashButtonState}
        trashButtonClick={trashButtonClick}
        isSweaterPart={props.isSweaterPart}
        selectedEditPattern={props.selectedEditPattern}
        useZoom={props.useZoom}
        zoom={zoom}
        setSelectedEditPattern={props.setSelectedEditPattern}
        setDoIfClickedOutside={props.setDoIfClickedOutside}
        mirror={props.mirror}
        setMirror={props.setMirror}
        setMirrorMemoBrush={props.setMirrorMemoBrush}
        setMirrorMemoPattern={props.setMirrorMemoPattern}
        getDrawType={props.getDrawType}
        moveMode={props.moveMode}
        setDrawType={props.setDrawType}
      />
      <div
        ref={_gridContainer}
        className="box showScrollbar"
        style={{
          display: "flex",
          flex: "1",
          overflowY: "auto",
        }}
      >
        <div>
          <Dialog
            open={alertOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
          >
            <DialogTitle id="alert-dialog-title">
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                {props.isSweaterPart
                  ? "Choose what to delete:"
                  : "Delete pattern?"}
              </p>
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} fullWidth={true}>
                Cancel
              </Button>
              {props.isSweaterPart && (
                <>
                  <Button
                    onClick={() => {
                      handleClose();
                      resetState(true);
                    }}
                    fullWidth={true}
                  >
                    {(props.drawable as SweaterPart)?.areaGroupName()}
                  </Button>
                  <Button
                    onClick={() => {
                      handleClose();
                      resetState(false);
                    }}
                    fullWidth={true}
                    autoFocus
                  >
                    Whole sweater
                  </Button>
                </>
              )}
              {!props.isSweaterPart && (
                <>
                  <Button
                    onClick={() => {
                      handleClose();
                      resetState(false);
                      SaveButtonClick(true);
                    }}
                    fullWidth={true}
                    autoFocus
                  >
                    Yes
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: Util.calculateGridColor(
              -1,
              -1,
              undefined,
              -2,
              warningOverlay,
              undefined
            ),
          }}
        >
          <div
            ref={_gridHTML}
            style={{
              padding: props.isSweaterPart ? "50px" : "0px 30px 0px 30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseLeave={() => {
              clearPreview();
              setSnap(false);
            }}
          >
            {props.drawable &&
              grid.map((gridY: any, y: number) => (
                <div style={{ display: "flex" }} key={y}>
                  {gridY.map((colorIndex: number, x: number) => (
                    <GridCell
                      blackBorders={calculateBlackBorders(
                        grid,
                        x,
                        y,
                        props.isSweaterPart
                      )}
                      sizeX={props.useZoom ? gridSize : 15}
                      sizeY={Math.round(
                        ((props.useZoom ? gridSize : 15) * 18) / 23
                      )}
                      drawBox={!props.isSweaterPart}
                      warningDescription={
                        warningOverlay &&
                        Global.warningDescriptionInfo(grid, x, y)?.description
                      }
                      warningColor={
                        warningOverlay &&
                        Global.warningDescriptionInfo(grid, x, y)?.color
                      }
                      warningOverlay={warningOverlay}
                      snap={snap}
                      colors={props.colors}
                      colorIndex={colorIndex}
                      x={x}
                      y={y}
                      key={x + "," + y}
                      onMouseOver={onMouseOver}
                      setLastPos={setLastPos}
                      updateUndo={updateUndo}
                    />
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Grid;
