import React from "react";
import cn from "classnames";
import Spinner from "../../icons/Spinner";

type Props = {
  onClick?: () => void;
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  disabled?: boolean;
  loading?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
  children?: any;
  style?: any;
  noPadding?: boolean;
};

const Button: React.FC<React.PropsWithChildren<Props>> = ({
  onClick = () => {},
  variant: variant = "primary",
  disabled = false,
  loading = false,
  type,
  className,
  children,
  style = {},
  noPadding = false,
}) => {
  return (
    <button
      style={style}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={cn(
        // Base styles
        // Removed: px-[3.75rem]
        (noPadding ? "" : "px-[3.75rem] py-4 ") +
          "text-par-b-bold flex items-center justify-center whitespace-nowrap rounded leading-none",
        {
          "bg-primary-cta bg-[length:130%] transition-[background-size] hover:bg-[length:140%] active:bg-[length:150%]":
            variant === "primary" && !disabled,
          "bg-primary-cta-bw": variant === "primary" && disabled,
          "bg-[position:center_60%] text-white": variant === "primary",
        },
        {
          "text-positive hover:border-neutral-50 active:border-positive":
            variant === "secondary" && !disabled,
          "text-neutral-20": variant === "secondary" && disabled,
          "border-2 border-neutral-20": variant === "secondary",
        },
        {
          "text-darkest-brown hover:border-neutral-50 active:border-darkest-brown":
            variant === "tertiary" && !disabled,
          "text-neutral-20": variant === "tertiary" && disabled,
          "border-2 border-neutral-20": variant === "tertiary",
        },
        { "cursor-default": disabled || loading },
        {
          "bg-primary-cta-brown bg-[length:130%] transition-[background-size] hover:bg-[length:140%] active:bg-[length:150%]":
            variant === "quaternary" && !disabled,
          "bg-primary-cta-bw": variant === "primary" && disabled,
          "bg-[position:center_60%] text-white": variant === "quaternary",
        },
        className
      )}
    >
      {loading ? (
        <Spinner
          className={cn("animate-spin", {
            "text-light-brown":
              variant === "tertiary" || variant === "secondary",
          })}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
