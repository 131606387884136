import { configureStore } from '@reduxjs/toolkit'
import { keySlice } from './keyslice'
import { knitabilitySlice } from './knitabilityslice'
// ...

export const store = configureStore({
  reducer: {
    key: keySlice.reducer,
    knitability: knitabilitySlice.reducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch