import Button from "../../components/ui/Button/Button";
import FacebookButton from "../../components/ui/Button/FacebookButton";
import Input from "../../components/ui/Input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import React, { useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { RegisterUserCredentials, RegisterUserSchema } from "../../types/auth";

type Props = {
  onSubmit: (registerCredentials: RegisterUserCredentials) => void;
  loading?: boolean;
  defaultValues?: Omit<Partial<RegisterUserCredentials>, "password">;
  className?: string;
};

type RegisterFieldErrors = {
  first_name?: FieldError | undefined;
  last_name?: FieldError | undefined;
  email?: FieldError | undefined;
  phone?: FieldError | undefined;
  password?: FieldError | undefined;
  confirmPassword?: FieldError | undefined;
  newsletter?: FieldError | undefined;
};

const RegisterForm: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  loading,
  defaultValues,
  className,
}) => {
  const { register, handleSubmit, control, clearErrors } =
    useForm<RegisterUserCredentials>({
      reValidateMode: "onSubmit",
      defaultValues,
      resolver: yupResolver(RegisterUserSchema),
    });

  const [errors, setErrors] = useState<RegisterFieldErrors>();

  const handleValid = (credentials: RegisterUserCredentials) =>
    onSubmit(credentials);

  const handleInvalid = (fieldErrors: RegisterFieldErrors) =>
    setErrors(fieldErrors);

  return (
    <div className={cn("flex flex-col gap-y-10", className)}>
      <p className="text-par-b-reg text-center">Registrer en Woolit-konto.</p>
      <form
        onSubmit={handleSubmit(handleValid, handleInvalid)}
        className="flex flex-col items-center gap-y-3"
      >
        <div className="grid w-full grid-cols-2 gap-x-4">
          <Controller
            control={control}
            name="first_name"
            render={({ field: { value, onChange, name } }) => (
              <Input
                autoComplete="given-name"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  clearErrors(name);
                }}
                name={name}
                error={errors?.first_name?.message}
                type="text"
                label="Fornavn"
              />
            )}
          />
          <Controller
            control={control}
            name="last_name"
            render={({ field: { value, onChange, name } }) => (
              <Input
                autoComplete="family-name"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  clearErrors(name);
                }}
                name={name}
                error={errors?.last_name?.message}
                type="text"
                label="Etternavn"
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange, name } }) => (
            <Input
              autoComplete="email"
              className="w-full"
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors(name);
              }}
              name={name}
              error={errors?.email?.message}
              label="Epost"
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { value, onChange, name } }) => (
            <Input
              autoComplete="tel-national"
              className="w-full"
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors(name);
              }}
              name={name}
              error={errors?.phone?.message}
              type="number"
              label="Telefonnummer"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange, name } }) => (
            <Input
              autoComplete="new-password"
              className="w-full"
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors(name);
              }}
              name={name}
              error={errors?.password?.message}
              type="password"
              label="Passord"
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field: { value, onChange, name } }) => (
            <Input
              autoComplete="new-password"
              className="w-full"
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors(name);
              }}
              name={name}
              error={errors?.password?.message}
              type="password"
              label="Bekreft passord"
            />
          )}
        />
        <div className="flex items-center gap-2">
          <input type="checkbox" {...register("newsletter")} />
          <label className="text-par-s-reg">
            Jeg ønsker oppdateringer på epost.
          </label>
        </div>
        <Button type="submit" className="mt-1 w-full" loading={loading}>
          Registrer bruker
        </Button>
      </form>
      <div className="my-2 flex h-px w-full items-center justify-center bg-neutral-20">
        <span className="text-par-s-reg bg-white px-2 text-neutral-50">
          eller
        </span>
      </div>
      <FacebookButton />
    </div>
  );
};

export default RegisterForm;
