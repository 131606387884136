import { SweaterPart } from "./SweaterPart";
import { KnitabilityProblem } from "./enums";
import { Settings } from "./static/settings";
import { Util } from "./static/util";

//Array maxsize = 2
function isMaskMultiple(sweaterparts: SweaterPart[], x: number, y: number) {
  const useFirst = sweaterparts.length === 1 || x < sweaterparts[0].sizeX;
  if (useFirst) return sweaterparts[0].isMask(x, y, 0, 0);
  return sweaterparts[1].isMask(x - sweaterparts[0].sizeX, y, 0, 0);
}

function drawColorMultiple(sweaterparts: SweaterPart[], x: number, y: number) {
  const useFirst = sweaterparts.length === 1 || x < sweaterparts[0].sizeX;
  if (useFirst) return sweaterparts[0].grid[y][x];
  return sweaterparts[1].grid[y][x - sweaterparts[0].sizeX];
}

function checkIfEqualLength(sweaterParts: SweaterPart[]) {
  if (sweaterParts.length === 1) return;
  if (sweaterParts.length > 2) throw new Error("Must be length 2");
  if (sweaterParts[0].grid[0].length !== sweaterParts[1].grid[0].length)
    throw new Error("Must be equal length in X");
  if (sweaterParts[0].grid.length !== sweaterParts[1].grid.length)
    throw new Error("Must be equal length in Y");
}

export function isKnitable(sweaterparts: SweaterPart[]) {
  checkIfEqualLength(sweaterparts);
  const connectedStartY = sweaterparts[0].connectY;
  const endY = sweaterparts[0].sizeY;
  const info = _isKnitable(sweaterparts, connectedStartY, endY, true);
  if (connectedStartY !== 0) {
    for (let sweaterpart of sweaterparts) {
      info.push(..._isKnitable([sweaterpart], 0, connectedStartY, false));
    }
  }
  return info;
}

function makeColorOffsetMap(
  sweaterparts: SweaterPart[],
  startY: number,
  endY: number
) {
  const sizeXTotal = Util.sum(sweaterparts.map((it) => it.sizeX));
  const sizeX = sweaterparts[0].sizeX;

  const colorMap: number[][] = [];
  const offsetMap: { [point: string]: number[] } = {};
  //x, y = position in colorMap
  //_x, _y = position in grid
  for (let _y = startY; _y < endY; _y++) {
    colorMap.push([]);
    for (let _x = 0; _x < sizeXTotal; _x++) {
      if (isMaskMultiple(sweaterparts, _x, _y)) {
        const y = _y - startY;
        const x = colorMap[y].length;
        colorMap[y].push(drawColorMultiple(sweaterparts, _x, _y));
        offsetMap[Util.point(x, y)] = [_x % sizeX, _y];
      }
    }
  }
  return [colorMap, offsetMap];
}

function checkColorDistanceProblem(
  colorMap: any,
  x: number,
  y: number,
  isConnected: boolean,
  colorDistanceProblemInfo: any
) {
  const colorDistanceProblem = colorDistanceProblemInfo.problem;
  const offsetXMax = colorDistanceProblemInfo.gap;

  const drawColor = colorMap[y][x];
  const directions = [-1, 1];
  for (let direction of directions) {
    let foundMatch = false;
    for (let offsetX = 1; offsetX <= offsetXMax; offsetX++) {
      let newX = x + offsetX * direction;
      const outOfBounds = newX !== Util.mod(newX, colorMap[y].length);
      if (!isConnected && outOfBounds) {
        foundMatch = true;
        break;
      }
      newX = Util.mod(newX, colorMap[y].length);
      const drawColorOffset = colorMap[y][newX];
      if (drawColor === drawColorOffset) {
        foundMatch = true;
        break;
      }
    }
    const isProblem = !foundMatch;
    if (isProblem) return colorDistanceProblem;
  }
  return KnitabilityProblem.None;
}

function findColorDistanceProblems(
  sweaterparts: SweaterPart[],
  startY: number,
  endY: number,
  isConnected: boolean,
  colorDistanceProblemInfo: any
) {
  const [colorMap, offsetMap]: any = makeColorOffsetMap(
    sweaterparts,
    startY,
    endY
  );

  const infos = [];
  for (let y = 0; y < colorMap.length; y++) {
    for (let x = 0; x < colorMap[y].length; x++) {
      const colorDistanceProblem = checkColorDistanceProblem(
        colorMap,
        x,
        y,
        isConnected,
        colorDistanceProblemInfo
      );
      if (colorDistanceProblem !== KnitabilityProblem.None) {
        const sweaterpart =
          x < colorMap[y].length / 2
            ? sweaterparts[0]
            : sweaterparts[sweaterparts.length - 1];
        const point = Util.point(x, y);
        const [_x, _y] = offsetMap[point];
        if (colorDistanceProblem !== KnitabilityProblem.None) {
          const warningGap = colorDistanceWarning.gap;
          const errorGap = colorDistanceError.gap;
          switch (colorDistanceProblem) {
            case KnitabilityProblem.Warning:
              infos.push([
                sweaterpart,
                _x,
                _y,
                `Challenge: Exceeds the maximum spacing of ${warningGap} pixels between the same color on a row`,
                Settings.knitabilityWarningColor,
              ]);
              break;
            case KnitabilityProblem.Error:
              infos.push([
                sweaterpart,
                _x,
                _y,
                `Problem: Exceeds the maximum spacing of ${errorGap} pixels between the same color on a row`,
                Settings.knitabilityErrorColor,
              ]);
              break;
          }
        }
      }
    }
  }
  return infos;
}

function addDxDy(infos: any) {
  for (let n = 0; n < infos.length; n++) {
    const sweaterpart = infos[n][0];
    infos[n][1] += sweaterpart.dx;
    infos[n][2] += sweaterpart.dy;
  }
  return infos;
}

function colorDistanceProblemsPositions(
  sweaterparts: SweaterPart[],
  startY: number,
  endY: number,
  isConnected: boolean,
  colorDistanceProblemInfo: any
) {
  const colorDistanceProblemPositions = findColorDistanceProblems(
    sweaterparts,
    startY,
    endY,
    isConnected,
    colorDistanceProblemInfo
  );
  return addDxDy(colorDistanceProblemPositions);
}

function maxColorsProblemPositions(
  sweaterparts: SweaterPart[],
  startY: number,
  endY: number,
  isConnected: boolean
): any {
  if (!isConnected && sweaterparts.length > 1) {
    const left = maxColorsProblemPositions(
      [sweaterparts[0]],
      startY,
      endY,
      false
    );
    const right = maxColorsProblemPositions(
      [sweaterparts[1]],
      startY,
      endY,
      false
    );
    return [...left, ...right];
  }
  const infos = [];
  const sizeXTotal = Util.sum(sweaterparts.map((it) => it.sizeX));
  const maxColors = 3;
  for (let y = startY; y < endY; y++) {
    const colors = [];
    for (let x = 0; x < sizeXTotal; x++) {
      if (isMaskMultiple(sweaterparts, x, y)) {
        colors.push(drawColorMultiple(sweaterparts, x, y));
      }
    }
    const uniqueColors = new Set(colors.flat());
    const isProblem = uniqueColors.size > maxColors;
    if (isProblem) {
      for (let sweaterpart of sweaterparts) {
        infos.push([
          sweaterpart,
          0,
          y,
          `Problem: Exceeds the maximum number of different colors of ${maxColors} on a row`,
          Settings.knitabilityErrorColor,
        ]);
      }
    }
  }
  return addDxDy(infos).map((it: any) => {
    it[1] = -1;
    return it;
  });
}

const colorDistanceWarning = {
  problem: KnitabilityProblem.Warning,
  gap: 15,
};
const colorDistanceError = {
  problem: KnitabilityProblem.Error,
  gap: 30,
};

function _isKnitable(
  sweaterparts: SweaterPart[],
  startY: number,
  endY: number,
  isConnected: boolean
) {
  const infos = [];
  infos.push(
    ...colorDistanceProblemsPositions(
      sweaterparts,
      startY,
      endY,
      isConnected,
      colorDistanceWarning
    )
  );
  infos.push(
    ...colorDistanceProblemsPositions(
      sweaterparts,
      startY,
      endY,
      isConnected,
      colorDistanceError
    )
  );
  infos.push(
    ...maxColorsProblemPositions(sweaterparts, startY, endY, isConnected)
  );
  return infos;
}
