import { useEffect, useState } from "react";
import Modal from "./login/Modal";
import GridButton from "./components/GridButton";
import Button from "./login/components/ui/Button/Button";
import { Global } from "./static/global";
import { Settings } from "./static/settings";
import { Util } from "./static/util";

function StartMenu(props: any) {
  const [open, setOpen] = useState(true);
  const sizes = ["XS", "S", "M", "L", "XL"];
  const [sizeIndex, setSizeIndex] = useState(2);
  const [knitMethodIndex, setKnitMethodIndex] = useState(0);

  const [lastSizeIndex, setLastSizeIndex] = useState(-1);
  const [lastKnitMethodIndex, setLastKnitMethodIndex] = useState(-1);
  const [hasStorageInfo, setHasStorageInfo] = useState(false);

  function clearStorage() {
    localStorage.removeItem(Global.getSizeIndexStorageName());
    localStorage.removeItem(Global.getKnittingMethodIndexStorageName());
    localStorage.removeItem(Global.getColorsStorageName());
    localStorage.removeItem(Global.getSweaterStorageName());
  }

  function clearStorageIfNewVersion() {
    const version = localStorage.getItem(Global.getVersionStorageName());
    if (version != Settings.version) {
      clearStorage();
    }
    localStorage.setItem(Global.getVersionStorageName(), Settings.version);
  }

  function loadStorageInfo() {
    const _lastSizeIndex = localStorage.getItem(
      Global.getSizeIndexStorageName()
    );
    if (_lastSizeIndex) {
      setLastSizeIndex(Number(_lastSizeIndex));
      setHasStorageInfo(true);
    }
    const _lastKnitMethodIndex = localStorage.getItem(
      Global.getKnittingMethodIndexStorageName()
    );
    if (_lastKnitMethodIndex) {
      setLastKnitMethodIndex(Number(_lastKnitMethodIndex));
      setHasStorageInfo(true);
    }
  }

  useEffect(() => {
    const pathName = window.location.pathname.substring(1);
    if (Util.isKey(pathName)) {
      props.setStartMenuOpen(false);
      return;
    }
    clearStorageIfNewVersion();
    loadStorageInfo();
  }, []);

  useEffect(() => {
    Global.setSizeIndex(sizeIndex);
  }, [sizeIndex]);

  useEffect(() => {
    Global.setShirtTypeIndex(knitMethodIndex);
  }, [knitMethodIndex]);
  return (
    <>
      <Modal
        open={props.startMenuOpen}
        handleClose={() => {}}
        customContainer={
          "relative z-10 h-full max-h-screen w-full overflow-scroll border border-neutral-10 bg-white shadow-xl scrollbar-hide md:h-auto md:max-h-[90vh] md:w-auto md:rounded-2xl"
        }
      >
        <div
          style={{
            display: "flex",
            height: "800px",
            width: "700px",
            padding: "40px",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <img src="ui/logo512.png" style={{ height: "25px" }}></img>
            <p style={{ fontSize: "20px", fontWeight: "400" }}> Woolit</p>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <h2>Design your knitted sweater!</h2>
            <p style={{ fontSize: "18px", marginBottom: "30px" }}>
              After you have completed your sweater design, you may publish a
              request to have it knitted or buy a custom yarn package to knit it
              yourself!
            </p>
            <h3>Size:</h3>
            <hr></hr>
            <div
              style={{ display: "flex", gap: "10px", marginBottom: "30px " }}
            >
              {sizes.map((it: string, index: number) => (
                <GridButton
                  title={`Chest: ${Settings.circumfencesCM[index]}cm\nLength: ${Settings.lengthCM[index]}cm`}
                  small
                  onClick={() => {
                    setSizeIndex(index);
                  }}
                  isSelected={index == sizeIndex}
                  isSelectedBrown
                  key={index}
                >
                  <p style={{ fontSize: "20px" }}>{it}</p>
                </GridButton>
              ))}
            </div>
            <h3>Knit method:</h3>
            <hr></hr>
            <div style={{ display: "flex", gap: "10px" }}>
              <GridButton
                size={50}
                onClick={() => setKnitMethodIndex(0)}
                customStyle={{
                  minWidth: "230px",
                  maxWidth: "230px",
                  height: "50px",
                }}
                imageURL="ui/Genser-ikon-1.png"
                isSelected={knitMethodIndex == 0}
                isSelectedBrown
              >
                Raglan
              </GridButton>
              <GridButton
                size={50}
                onClick={() => setKnitMethodIndex(1)}
                customStyle={{
                  minWidth: "230px",
                  maxWidth: "230px",
                  height: "50px",
                }}
                imageURL="ui/Genser-ikon-2.png"
                isSelected={knitMethodIndex == 1}
                isSelectedBrown
              >
                Stitched Sleeves
              </GridButton>
            </div>
            <Button
              onClick={() => {
                clearStorage();
                props.setStartMenuOpen(false);
              }}
              variant="quaternary"
              style={{
                width: "180px",
                borderRadius: "40px",
                margin: "auto",
                marginTop: "30px",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: "18px",
                }}
              >
                Start
              </p>
            </Button>
          </div>
          <div
            className="box"
            style={{
              backgroundColor: "#f9f5f4",
              borderRadius: "20px",
              borderWidth: "0.5px",
              margin: "-20px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <h3>Tips for knitability</h3>
            <hr></hr>
            <ul
              style={{
                listStyleType: "square",
                marginLeft: "15px",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <li>Repetitive patterns</li>
              <li>Maximum 30 pixels in between the same color on same row</li>
              <li>Maximum 3 colors in one knit row</li>
              <li>
                <div style={{ display: "flex" }}>
                  Click{" "}
                  <img
                    style={{
                      height: "20px",
                      margin: "5px",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                    src="ui/eye_icon.png"
                  ></img>{" "}
                  next to your knitability status to see problem areas
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
      <Modal
        open={hasStorageInfo}
        handleClose={() => {
          setHasStorageInfo(false);
        }}
      >
        <div
          style={{
            width: "500px",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            padding: "40px",
            gap: "40px",
          }}
        >
          <p
            style={{
              margin: "auto",
            }}
          >
            Continue where you left of?
          </p>
          <p
            style={{
              margin: "auto",
            }}
          >
            {`(Size: "${
              Settings.sizes[lastSizeIndex]
            }", Knit Method: "${Util.titleCase(
              Settings.shirtTypes[lastKnitMethodIndex]
            )}")`}
          </p>
          <div
            style={{
              display: "flex",
              flex: "1",
              gap: "10px",
            }}
          >
            <button
              className="box hoverBrownBordered"
              style={{ flex: "1" }}
              onClick={() => {
                setHasStorageInfo(false);
              }}
            >
              No
            </button>
            <button
              className="box hoverBrownBordered"
              style={{ flex: "1" }}
              onClick={() => {
                setSizeIndex(lastSizeIndex);
                setKnitMethodIndex(lastKnitMethodIndex);
                setHasStorageInfo(false);
                props.setStartMenuOpen(false);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default StartMenu;
