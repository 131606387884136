import { SweaterPartArea, SweaterPartAreaGroup } from "./enums";
import { Settings } from "./static/settings";
import { Global } from "./static/global";
import { colorsScene } from "./knittingpreview/scene";

export class SweaterPart {
  name: string;
  area: SweaterPartArea;
  grid: number[][];
  dirtyPositionsGrid: boolean[][];
  corner1X: number;
  corner1Y: number;
  corner2X: number;
  corner2Y: number;
  connectY: number;
  inverted: boolean;
  dx: number;
  dy: number;
  startXPixel: number;
  startYPixel: number;
  endXPixel: number;
  endYPixel: number;
  scaleX: number;
  scaleY: number;
  sizeX: number;
  sizeY: number;
  //corner1      corner
  //
  //corner       corner2
  constructor(
    name: string,
    area: SweaterPartArea,
    grid: number[][],
    corner1X: number,
    corner1Y: number,
    corner2X: number,
    corner2Y: number,
    _connectY: number,
    inverted: boolean = false
  ) {
    this.name = name;
    this.area = area;
    this.grid = grid;
    this.corner1X = corner1X / 4096;
    this.corner1Y = corner1Y / 4096;
    this.corner2X = corner2X / 4096;
    this.corner2Y = corner2Y / 4096;
    let connectY = _connectY;
    if (_connectY === -1) {
      connectY = this.corner1Y;
      connectY *= 4096;
    }
    this.dirtyPositionsGrid = [];
    this.inverted = inverted;
    this.setDirty();

    // const minGridSizeX = 138;
    // const minGridSizeY = 116;

    this.startXPixel = this.corner1X * 4096;
    this.startYPixel = this.corner1Y * 4096;
    this.endXPixel = this.corner2X * 4096;
    this.endYPixel = this.corner2Y * 4096;

    this.scaleX = 4096 / Global.canvasWidth;
    this.scaleY = 4096 / Global.canvasHeight;
    //NB BUG: if endXPixel is too large, mirror will not work.
    //Should probably use calculateGridSize for calculation of sizeX
    this.sizeX = Math.round(
      (this.endXPixel - this.startXPixel) / (Settings.maskWidth * this.scaleX)
    );
    this.sizeX += this.sizeX % 2
    this.sizeY = Math.round(
      (this.endYPixel - this.startYPixel) / (Settings.maskHeight * this.scaleY)
    );
    this.sizeY += this.sizeY % 2
    this.connectY = Math.round(
      (connectY - this.startYPixel) / (Settings.maskHeight * this.scaleY)
    );

    if (this.sizeX % 2 == 1 && area !== SweaterPartArea.Collar){
      throw new Error(`Odd number (${this.sizeX}) is not allowed for sizeX.\nTo fix: Change corner1X/corner2X accordingly.`)
    }

    this.dx = 0; //Math.max(Math.ceil((minGridSizeX - this.sizeX) / 2), 10)
    this.dy = 0; //Math.max(Math.ceil((minGridSizeY - this.sizeY) / 2), 0)
  }

  // Fully redraw the SweaterPart inside the scene.
  setDirty() {
    function make2DArray(x: number, y: number, fillWith: any) {
      return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
    }
    this.dirtyPositionsGrid = make2DArray(
      this.grid[0].length,
      this.grid.length,
      true
    );
  }

  areaGroup() {
    switch (this.area) {
      case SweaterPartArea.LeftArm:
        return SweaterPartAreaGroup.Arms;
      case SweaterPartArea.RightArm:
        return SweaterPartAreaGroup.Arms;
      case SweaterPartArea.FrontTorso:
        return SweaterPartAreaGroup.Torso;
      case SweaterPartArea.BackTorso:
        return SweaterPartAreaGroup.Torso;
      case SweaterPartArea.Collar:
        return SweaterPartAreaGroup.Collar;
    }
  }

  areaGroupName() {
    return SweaterPartAreaGroup[this.areaGroup()!];
  }

  isArm() {
    return this.areaGroup() === SweaterPartAreaGroup.Arms;
  }

  isTorso() {
    return this.areaGroup() === SweaterPartAreaGroup.Torso;
  }

  isCollar() {
    return this.areaGroup() === SweaterPartAreaGroup.Collar;
  }

  updateGrid(x: number, y: number, newValue: number) {
    if (this.grid[y][x] !== newValue) {
      this.grid[y][x] = newValue;
      this.dirtyPositionsGrid[y][x] = true;
    }
  }

  copyGrid() {
    const shallowGrid = [];
    for (let innerGrid of this.grid) {
      shallowGrid.push([...innerGrid]);
    }
    return shallowGrid;
  }

  isSweaterPart() {
    return true;
  }

  findOppositePart(allParts: SweaterPart[]) {
    return allParts.filter(
      (it) => it.areaGroup() === this.areaGroup() && it !== this
    )[0];
  }

  isMask(x: number, y: number, _dx?: number, _dy?: number) {
    const dx = _dx ?? this.dx;
    const dy = _dy ?? this.dy;
    const sizeX = this.sizeX;
    const sizeY = this.sizeY;
    const scaleX = this.scaleX;
    const scaleY = this.scaleY;
    const startXPixel = this.startXPixel;
    const startYPixel = this.startYPixel;
    const endXPixel = this.endXPixel;
    const endYPixel = this.endYPixel;

    let minLimitXDraw = dx;
    let maxLimitXDraw = sizeX + dx;
    let minLimitYDraw = dy;
    let maxLimitYDraw = sizeY + dy;

    if (!(x >= minLimitXDraw && x < maxLimitXDraw)) {
      return false;
    }
    if (!(y >= minLimitYDraw && y < maxLimitYDraw)) {
      return false;
    }
    const midXPixel = (startXPixel + endXPixel) / 2;
    const midYPixel = (startYPixel + endYPixel) / 2;

    let xPixel = Math.round(
      (x - dx - sizeX/2) * scaleX * Settings.maskWidth + midXPixel
    );
    let yPixel = Math.round(
      (y - dy - sizeY/2) * scaleY * Settings.maskHeight + midYPixel
    );


    let xPixelNext = Math.round((x + 1 - dx - sizeX/2) * scaleX * Settings.maskWidth + midXPixel)
    let yPixelNext = Math.round((y + 1 - dy - sizeY/2) * scaleY * Settings.maskHeight + midYPixel)
    //let xPixelNext = xPixel + Math.round(Settings.maskWidth * scaleX);
    //let yPixelNext = yPixel + Math.round(Settings.maskHeight * scaleY);
    // Can consider merging the math.round (first commented),
    // but doing so results in unsymmetry, which is why this is done instead

    //NB: Consider caching this
    let NW = this.pixelData(Global.imageData, xPixel, yPixel) >= 128;
    let NE = this.pixelData(Global.imageData, xPixelNext, yPixel) >= 128;
    let SW = this.pixelData(Global.imageData, xPixel, yPixelNext) >= 128;
    let SE = this.pixelData(Global.imageData, xPixelNext, yPixelNext) >= 128;
    return NW || NE || SW || SE; //Do four corner checks, and adjust output depending
  }

  pixelData(imageData: any, x: number, y: number) {
    let index = x * 4 + y * 4 * 4096;
    if (index >= 4096 * 4096 * 4) throw new Error("out of index");
    return imageData.data[index];
  }

  countYarn() {
    const colors: { [id: string]: number } = {};
    for (let y = 0; y < this.sizeY; y++) {
      for (let x = 0; x < this.sizeX; x++) {
        if (this.isMask(x + this.dx, y + this.dy)) {
          const colorSceneIndex = this.grid[y][x];
          const colorScene = colorsScene[colorSceneIndex];
          const colorIndex = Settings.colorsAll.indexOf(colorScene);
          const colorID: string = Settings.colorsAllID[colorIndex];
          if (!(colorID in colors)) {
            colors[colorID] = 0;
          }
          colors[colorID] += 1;
        }
      }
    }
    return colors;
  }
}
